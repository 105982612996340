/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import CryptoES from 'crypto-es';
import {
  SITS_API_URL, CRED_KEY,
} from '../Config';
import { currentFYyear } from '../constants';
import { defaultSortValueforApi } from '../components/searchpage/filters/Sortfiltercomponent';

// Function to retrieve token and add it in a request
const retrieveToken = () => {
  const bytes = CryptoES.AES.decrypt(
    window.sessionStorage.getItem('token'),
    CRED_KEY,
  );
  const decryptedData = JSON.parse(bytes.toString(CryptoES.enc.Utf8));

  const config = {
    headers: {
      Authorization: `Bearer ${decryptedData}`,
    },
  };

  return config;
};

const retrieveCountryConfig = async (locale, success) => {
  const config = retrieveToken();
  const data1 = {
    localeCode: locale,
  };
  axios
    .post(`${SITS_API_URL}/api/Config/GetLocaleConfig`, data1, config)
    .then((response1) => {
      const data2 = {
        localeCode: response1.data.supportLocales,
      };
      axios.post(`${SITS_API_URL}/api/Config/GetLocaleConfig`, data2, config)
        .then((response2) => {
          success(response2.data, response1.data);
        });
    });
};

const retrievePendingdata = async (localeCode, success) => {
  const config = retrieveToken();
  const data = {
    locale_code: localeCode,
    financialYear: currentFYyear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/PendingTranslationCount`, data, config)
    .then((response) => {
      success(response); // The response data is assign to success function
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrieveGraphdata = async (localeCode, selectedYear, success) => {
  const config = retrieveToken();
  const data = {
    locale_code: localeCode,
    financialYear: selectedYear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/CountOfTextsAndWordsOfNewAndReleasedStatus`, data, config)
    .then((response) => {
      success(response); // The response data is assign to success function
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrievedueindata = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const config = retrieveToken();
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: selectedFilters.supportInfoFilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueIn`,
      data,
      config,
    )
    .then((response) => {
      success(response, filters); // The response data is assign to success function
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrievedueindataCount = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const config = retrieveToken();
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: selectedFilters.supportInfoFilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
      data,
      config,
    )
    .then((response) => {
      success(response, filters, selectedFilters);
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrieveDueinDataCountForFilter = async (
  selectedlocal,
  selectedFilters,
  success,
) => {
  const config = retrieveToken();
  const dueinCountPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: [],
    status: selectedFilters.Statusfilters,
    sort: '',
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: selectedFilters.supportInfoFilters,
  };
  const objecttypePayload = {
    locale_code: selectedlocal,
    object_type: [],
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: selectedFilters.supportInfoFilters,
  };
  const statusPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: [],
    sort: '',
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: selectedFilters.supportInfoFilters,
  };
  const categoryPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
    category: [],
    supportLocaleStatus: selectedFilters.supportInfoFilters,
  };
  const supportInformationPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
    category: selectedFilters.categoryfilters,
    supportLocaleStatus: [],
  };

  const itemsCountReqs = [
    `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInObjectTypeFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInStatusFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInCategoryFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInSupportCountryStatusFiltersCount`,

  ];

  const dueinRequest = axios.post(
    itemsCountReqs[0],
    dueinCountPayload,
    config,
  );
  const objecttypeRequest = axios.post(
    itemsCountReqs[1],
    objecttypePayload,
    config,
  );
  const statusRequest = axios.post(
    itemsCountReqs[2],
    statusPayload,
    config,
  );
  const categoryRequest = axios.post(
    itemsCountReqs[3],
    categoryPayload,
    config,
  );
  const supportInformationRequest = axios.post(
    itemsCountReqs[4],
    supportInformationPayload,
    config,
  );
  // eslint-disable-next-line max-len
  Promise.all([dueinRequest, objecttypeRequest, statusRequest, categoryRequest, supportInformationRequest]).then((response) => {
    success(
      response[0].data,
      response[1].data,
      response[2].data,
      response[3].data,
      response[4].data,
    );
  });
};

export {
  retrieveCountryConfig,
  retrievedueindata,
  retrievedueindataCount,
  retrieveGraphdata,
  retrievePendingdata,
  retrieveDueinDataCountForFilter,
};
