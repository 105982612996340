/* eslint-disable default-param-last */
export const selectedFiltersIntoList = (data) => ({
  type: 'SELECTED_FILTERS',
  payload: data,
});

export const listofFilters = (data) => ({
  type: 'LIST_OF_FILTERS',
  payload: data,
});

const initialState = {
  filtersList: {
    objecttypeFilters: [],
    statusFilters: [],
    userIdFilters: [],
    dueinFilters: [],
    categoryFilters: [],
    supportInfoFilter: [],
  },
  selectedFiltersList: [],
};

export const searchPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_OF_FILTERS':
      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          objecttypeFilters: [...action.payload.objecttypeFilters],
          statusFilters: [...action.payload.statusFilters],
          userIdFilters: [...action.payload.useridFilters],
          dueinFilters: [...action.payload.dueinFilters],
          categoryFilters: [...action.payload.categoryFilters],
          supportInfoFilter: [...action.payload.supportinfoFilters],
        },
      };
    case 'SELECTED_FILTERS':
      return {
        ...state,
        selectedFiltersList: [action.payload],
      };
    default:
      return state;
  }
};
