/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable */
import React, { useState, useLayoutEffect, useImperativeHandle, forwardRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table, { TableBody, TableHeader } from '@ingka/table';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/tag/style.scss';
import '@ingka/tooltip/style.scss';
import Tooltip from '@ingka/tooltip';
import errorHeader from '../messages/errorMessages.json'
import { selectedPopupValue } from '../App';
import { saveTranslation,
  navigateToEditPage,
  saveTranslationInTm,
  clearCountApiCalls,
  getConnectedProductsInfo,
  getHistoryInfo,
  releaseTranslatedTexts,
  getMatchesSuggestions,
} from '../api/Apirequests';
import { useSelector } from 'react-redux';
import '../components/searchpage/results/Resultscomponent.scss';
import Pill from '@ingka/pill';
import SSRIcon from '@ingka/ssr-icon';
import Button from '@ingka/button';
import incorrect from "@ingka/ssr-icon/paths/incorrect";
import Loadingcomponent from '../common-components/loader/Loadingcomponent';
import ErrorHandler from '../common-components/errorhandler/ErrorHandler';
import SitsContext from '../context/SitsContext';
import EditorBottomLayout from '../components/editorpage/layout/EditorBottomLayout';
import ConnectedProductsModal from '../components/editorpage/editorBottomRightSection/connectedProducts/ConnectedProductsModal';
import Contextmenu from '../components/editorpage/contextmenu/Contextmenu';
import 'focus-visible';
import { gObjectTypeValues } from '../api/Apirequests';
import EditorPageReleaseModal from '../components/editorpage/editorPageModal/EditorPageReleaseModal';
import ValidationPopup from '../common-components/validationPopup/ValidationPopup';
import validationMessage from '../messages/validationMessages.json'
import CommercialMessage from '@ingka/commercial-message';
import Skeletonloadingcomponent from '../common-components/loader/Skeletonloadingcomponent';
import semiAutomaticCountries from '../countriesData/semiAutomaticCountries.json';
import { spaceAfterhundreds } from '../constants';
import toastMsg from '../messages/toastMessages.json';
import metaDataForObjects from '../enabledObjects/metaDataForObjects.json'; 
import { expandingInformationInPill } from '../constants';



export let tab_sel = ''; // to capture the tab from where the data is passed
export let correlationIdEditorPage = [];
let segmentChangesFlag = false;
let loadFewTextsIds = [];
let editValueLocal = [];
let segmentsForStore = [];
let extendedDescription = [];
let extendedUsedIn = [];
let updatedOnDate = '';
let tmPayload = [];
let historyTabPayload = {
  textid: null,
  source_locale_code: null,
  target_locale_code: null,
  object_type_short: null,
  isParentEnabled: true,
}

const Editorpage = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  tab_sel = sitsContext.editorSearchPageLocationData.selectedPageTitle; // tab from where the data is passed
  let selectedtabpath  = tab_sel.toLowerCase();
  let searial = ''; // To capture the serial number in results table
  const [editedTextDetails, setEditedTextDetails] = useState([]);
  const [metaDataValue, setMetaDataValue] = useState({
    objectType: null,
    category: null,
    description: null,
    type: null,
    updatedBy: null,
    charCount: null,
    name: null,
    usedin: null,
    mainProductType: null
  });
  const [reRender, setIsReRender] = useState(false);
  const [editValue, setEditValue] = useState([]);
  const [selectedTextDetails, setSelectedTextDetails] = useState({
    textId: null,
    partId: null,
    partName: null,
    segmentId: null,
  });
  const [isTableRowChanged, setIsTableRowChanged] = useState(false);
  const [multiSegmentDetails, setIsMultiSegmentDetails] = useState([]);
  const [isConfirmButtonIsClicked, setIsConfirmButtonIsClicked] = useState(false);
  const [directionLang, setDirectionLang] = useState('ltr');
  const [trimSpace, setTrimSpace] = useState(false)
  const [saveIdOfTextArea, setSaveIdOfTextArea] = useState({
    id: null,
    translation: null,
  });
  const [characterExceededError, setCharacterExceededError] = useState(false);
  const [characterExceededData, setCharacterExceededData] = useState({
    textId: null,
    partId: null,
    partName: null,
    segmentId: null,
    maximumCharacterCount: 0,
    selectedPartHasError: false,
  });
  const [selectedSegment, setSelectedSegment] = useState({
        textId: null,
        partId: null,
        partName: null,
        objShort: null,
        segmentId: null,
        translationSuggestionLength: null,
        confirmButtonClicked: null,
        sourceSegmentText: null,
        initialRendering: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [enableErrorHandler, setEnableErrorHandler] = useState(false);
  const [errorScenarioNumber, setErrorScenarioNumber] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [matchesSuggestionSelected, setMatchesSuggestionSelected] = useState(false);
  const [translationMemoryPayload, setTranslationMemoryPayload] = useState([]);
  const [segmentsChanges, setSegmentsChanges] = useState([]);
  const [navigateEditPagePayload, setNavigateEditPagePayload] = useState([]);
  const [autoSave, setAutoSave] = useState(false);
  const [typedText, setTypedText] = useState(null);
  const [savedText, setSavedText] = useState(null);
  const [isAutoSaveCompleted, setIsAutoSaveCompleted] = useState(null);
  const [editorBottomHeight, setEditorBottomHeight] = useState(0);
  const [editorBottomResizeValue, setEditorBottomResizeValue] = useState(0);
  const [confirmButtonClickedOnEmptyText, setConfirmButtonClickedOnEmptyText] = useState(false);
  const [changesSavedAtleastOnce, setChangesSaved] = useState(false);
  const [callConnectedProducts, setCallConnectedProducts] = useState(false);
  const [confirmedTexts, setConfirmedTexts] = useState([...sitsContext.dataOfRelaseTexts.releaseButtonStatusCount]);
  const [displayReleaseModal, setDisplayReleaseModal] = useState(false);
  const [editorTableScrollPosition, setEditorTableScrollPosition] = useState(null);
  const [showHistoryTab, setShowHistoryTab] = useState(false);
  const [autoSaveFail, setAutoSaveFail] = useState(false);
  const [textsToBeLoaded, setTextsToBeLoaded] = useState([...sitsContext.editorSearchPageLocationData.selectedTextData]); 
  const [validationErrorPopupEnabled, setValidationErrorPopupEnabled] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState({
    validationHeader: null,
    validationMessage: null,
    buttonEnable: null,
  })
  const [validationTooltipMessage, setValidationTooltipMessage] = useState('');
  const [loadMatchesSuggestions, setLoadMatchesSuggestions] = useState(false);
  const [segmentToGetMatches, setSegmentToGetMatches] = useState(null);
  const [initialCheck, setInitialCheck] = useState(false);
  const [triggerForwardCheck, setTriggerForwardCheck] = useState(false);
  const [characterCountMetadata, setCharacterCountMetadata]=useState(0);
  const [expandButton, setExpandButton]= useState(false);
  const [usedInExpandButton, setUsedInExpandButton] = useState(false);


  // Capturing the keyboard events and keyboard keys
  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardShortcuts, false);
    return () => document.removeEventListener('keydown', handleKeyboardShortcuts, false);
  }, []);

  //Rerender an editor table if it's needed
  useEffect(() => {
    if (reRender === true) {
      setTimeout(() => {
        setIsReRender(false);
      }, 1000)
    }
  }, [reRender]);

  useLayoutEffect( () => {
    sitsContext.updateEditorBottomLeftHeader('matches');
      if(confirmedTexts.length > 0 ) {
        props.onDisableReleaseHandler(false);
      } else if(confirmedTexts.length === 0) {
        props.onDisableReleaseHandler(true);
      }
    if (sessionStorage.getItem('funcCalled') === 'true') {
    sessionStorage.setItem('funcCalled', 'false');
    loadFewTextsIds = [];
    segmentsForStore = [];
    setIsLoading(true);
    clearCountApiCalls();
    let languageCode = selectedPopupValue.selectedlang;
    if (languageCode === "AR" || languageCode === "HE") {
      setDirectionLang('rtl');
    }
    splittingPayLoadData();
  }
  }, []);

  // Loading only few texts from the large number of texts 
  const splittingPayLoadData = async () =>{ 
    if(textsToBeLoaded.length >= 30){
       loadFewTextsIds = textsToBeLoaded.splice(0, 30)
       setTextsToBeLoaded(textsToBeLoaded);
    } else if(textsToBeLoaded.length < 30){
       loadFewTextsIds = textsToBeLoaded.splice(0, textsToBeLoaded.length)
    }
     setEditValue((prevState) => [...prevState, ...loadFewTextsIds]);
     editorPageApiCall(loadFewTextsIds);
  }
  
useEffect(() => {
  const standardScreenWidth = 1240;
  const standardCharacterCount = 25;
  const calculatedCount = Math.round((window.innerWidth / standardScreenWidth) * standardCharacterCount);
  setCharacterCountMetadata(calculatedCount);
}, [props.windowResize]);

  useEffect(() => {
    if (sitsContext.bannerVisible === true) {
      const topEditorTable = document.getElementById('editorContainer');
      const bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - props.heightofbanner - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    } else {
      const topEditorTable = document.getElementById('editorContainer');
      const bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    }
}, [sitsContext.bannerVisible]);

  const editorPageApiCall =(apiPayLoadData)=>{
    if(sitsContext.editorSearchPageLocationData.selectedTextData.length === 1){
      editValueLocal = [...editValue, ...apiPayLoadData];
      setNavigateEditPagePayload(editValueLocal);
      navigateToEditPage(apiPayLoadData, getEditorData) 
    } else if(sitsContext.editorSearchPageLocationData.selectedTextData.length > 1){
      editValueLocal = [...editValue, ...apiPayLoadData];
      setNavigateEditPagePayload(editValueLocal);
      let incrementLength = 0 
      const updateBulkText = async () => {
     while(incrementLength < apiPayLoadData.length){
      await navigateToEditPage([apiPayLoadData[incrementLength]], getEditorData);
      incrementLength ++
      }
     }
     updateBulkText();
    }
  }

  const getEditorData = (response, isConfirmButtonClicked) => {
    let topEditorTable;
    let bottomEditorTable;
    if (sitsContext.bannerVisible === true) {
      topEditorTable = document.getElementById('editorContainer');
      bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - props.heightofbanner - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    } else {
      topEditorTable = document.getElementById('editorContainer');
      bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    }
  if (isConfirmButtonClicked === 'ConfirmClicked') {
    // Below two lines of code is for close button functionality which will call the API even
    // though user hasn't made any change but clicked on the confirm button
    setTypedText("");
    setSavedText("");
    setSelectedSegment((prevState) => ({
      ...prevState,
      textId: null,
      partId: null,
      partName: null,
      objShort: null,
      segmentId: null,
      translationSuggestionLength: null,
      confirmButtonClicked: true,
      sourceSegmentText: null,
    }));
    setSaveIdOfTextArea((prevState) => ({
      ...prevState,
      id: null,
      translation: null
    }));
 }
  if (response.status === 200) {
    let res = response.data;
    let selectedMetaData;
    let charCountForMultipleSegments = 0;
    let translationMemoryPayloadLocal = [];
    let translationUnitSegmentsLocal = [];
    let editedText = {};
    let editedTextDetails_segments = [];
    let productsTranslationDetails = [];
    let individualTextPartLocal = {};
    let textObjectPartsLocal = [];
    let selectedText =[];
    let hasValidRowCome = false
    let localMultiSegmentData = [];
    let selectedSegmentUpdated = false;
    let selectedSegmentLocal = {};
    let selectedIdUpdated = false;
    let segmentsChangesLocal = [];

    if(sitsContext.editorSearchPageLocationData.selectedTextData.length === 1){
      setTimeout(() => {
        topEditorTable.scrollTo(0, 0);
      }, 100);
      let selectedData = {
      textId: res[0].textid,
      partId: res[0].textpart[0].partId,
      partName: res[0].textpart[0].partName,
      segmentId: 0,
    }
    selectedText = {
      textId: res[0].textid,
      partId: res[0].textpart[0].partId,
      partName: res[0].textpart[0].partName,
      segmentId: 0,
    }
    setSelectedTextDetails(selectedData);
  }
    // The below part is to handle the addition of translation text part into each segments
    // basically what is does it take out the first suggestion from translationSuggestions
    // object and place it under the each respective segments object
    response.data.forEach((data, textIndex) => {
      textObjectPartsLocal = [];
      data.textpart.forEach((text_part, textPartIndex) => {
        if (text_part.text !== "" && text_part.text !== null) {
          if (hasValidRowCome === false) {
            hasValidRowCome = true
            text_part.firstValidRow = true;
           }
          }
          text_part.segments.forEach((segements_data, segmentsIndex) => {
            let validSuggestionsCount = 0;

          if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1
            && isConfirmButtonClicked === undefined){
              if (textIndex === 0 && textPartIndex === 0 && segmentsIndex === 0) {
                sitsContext.updateChangeMatchesDetails(true);
              }
              if (segements_data.segment !== "" && segements_data.segment !== null
                && selectedSegmentUpdated === false
              ) {
                setSelectedSegment({
                  textId: data.textid,
                  partId: text_part.partId,
                  partName: text_part.partName,
                  objShort: data.objNameShort,
                  segmentId: 0,
                  translationSuggestionLength: segements_data.translationsSuggestions.length,
                  confirmButtonClicked: false,
                  sourceSegmentText: segements_data.segment,
                  initialRendering: true,
                  statusOfTheText: text_part.status,
                })
                selectedSegmentLocal = {
                  textId: data.textid,
                  partId: text_part.partId,
                  partName: text_part.partName,
                  objShort: data.objNameShort,
                  segmentId: 0,
                  translationSuggestionLength: segements_data.translationsSuggestions.length,
                  confirmButtonClicked: false,
                  sourceSegmentText: segements_data.segment,
                  initialRendering: true,
                }
                setSegmentToGetMatches(segements_data.segment);
                selectedSegmentUpdated = true;
              }
          }

        if (sitsContext.editorSearchPageLocationData.selectedTextData.length !== 1
          && isConfirmButtonClicked === undefined){
          if (sitsContext.editorSearchPageLocationData.selectedTextData[0].textid === data.textid
            && textIndex === 0 && textPartIndex === 0 && segmentsIndex === 0) {
            setSelectedSegment((prevState) => ({
              ...prevState,
              initialRendering: true,
            }))
          }
        }
            segements_data.translationsSuggestions.forEach((suggestions_data, translationSuggestionsIndex) => {
              // Calculation matches suggestions length    
              if ((segements_data.segementID === 0) && textIndex === 0  && (suggestions_data.fromTM === true) && (suggestions_data.translation !== null)
                  && (suggestions_data.translation !== "")) {
                     validSuggestionsCount = validSuggestionsCount + 1;
                  }  
              
              // When segments index is 0, do below logic
                  if (segmentsIndex === 0) {
                   // If segments length is greater than 1   
                    if(text_part.segments.length > 1) {
                      individualTextPartLocal = {
                        textId: data.textid,
                        textPart: text_part.partId,
                        textPartName: text_part.partName,
                        value: 'multiple segments ' + data.textid + "-" + text_part.partId,
                        sourceCompleteText: text_part.text,
                        textSegments: [],
                      }
                      // is segments length is equal to 1
                  } else if (text_part.segments.length === 1) {
                    individualTextPartLocal = {
                      textId: data.textid,
                      textPart: text_part.partId,
                      textPartName: text_part.partName,
                      value: suggestions_data.translation,
                      sourceCompleteText: text_part.text,
                      textSegments: [],
                    }
                  }
                  textObjectPartsLocal.push(individualTextPartLocal);
                }


                if (textPartIndex === (data.textpart.length - 1)) {
                  editedText = {
                    textId: data.textid,
                    objFullName: data.objectType,
                    status: text_part.status,
                    previousStatus: text_part.status,
                    target_localeCode: selectedPopupValue.selectedlangvalue,
                    user: sessionStorage.getItem('userId'),
                    category: data.category,
                    name:data.name,
                    description: data.description,
                    mainProductType: data.mainProductType,
                    usedIn: data.usedIn,
                    sourceSystem: data.sourceSystem,
                    objShortName: data.objNameShort,
                    textObjectParts: [...textObjectPartsLocal],
                  }

                  // Pushing database payload object on segment index = 0
                  if (productsTranslationDetails.length !== 0) {
                    let prodTranslationIndex = productsTranslationDetails.findIndex(productData => (
                      (productData.textId === data.textid)
                    ));
                    if (prodTranslationIndex === -1) {
                      productsTranslationDetails.push(editedText);
                    }
                  } else {
                    productsTranslationDetails.push(editedText);
                  }
                }
                  
                  // If matches suggestions = 0 for each segment
                    if (translationSuggestionsIndex === 0) {
                    let segments_editedDetails = {
                        textId: data.textid,
                        segmentId: segements_data.segementID,
                        textPartName: text_part.partName,
                        sourceSegment: segements_data.segment,
                        translatedSegment: suggestions_data.translation,
                        initialTranslatedSegment: suggestions_data.translation,
                        isLineBreakingSegment: segements_data.isLineBreakingSegment,
                        isEmptyNewLine: segements_data.isEmptyNewLine,
                        delimiter: segements_data.delimiter,
                        baseScore: suggestions_data.baseScorce,
                        baseLocale: selectedPopupValue.selectedlangvalue,
                        isSemiAutomatic: true,
                        match: suggestions_data.baseScorce,
                        textChanged: suggestions_data.textChanged,
                        version: ""
                    }
                    editedTextDetails_segments.push(segments_editedDetails);

                    // Updating local segment changes details
                    let individualSegmentChangesDetails = {
                      textId: data.textid,
                      partId: text_part.partId,
                      partName: text_part.partName,
                      segmentId: segements_data.segementID,
                      sourceText: segements_data.segment,
                      targetText: suggestions_data.translation, // Only variable is this property's value
                    }

                    segmentsChangesLocal.push(individualSegmentChangesDetails);
 
                    let translationUnitRequest = {
                      Settings: {
                        ExistingTUsUpdateMode: 'LeaveUnchanged'
                      },
                      TranslationUnit: {
                        Source: {
                          Language: 'en-gb',
                          Text: segements_data.segment
                        },
                        Target: {
                          Language: selectedPopupValue.selectedlangvalue,
                          Text: suggestions_data.translation
                        },
                        textType: data.objectType,
                        createdBy: suggestions_data.createdBy,
                        creationDate: (suggestions_data.creationDate === null
                          || suggestions_data.creationDate === ""
                          || suggestions_data.creationDate.split('-')[1] === undefined)
                          ? null
                          : suggestions_data.creationDate.split('-')[1] + "/"
                          + suggestions_data.creationDate.split('-')[2] + "/"
                          + suggestions_data.creationDate.split('-')[0],
                        usedBy: suggestions_data.usedBy,
                        useDate: (suggestions_data.useDate === null
                          || suggestions_data.useDate === ""
                          || suggestions_data.useDate.split('-')[1] === undefined)
                          ? null
                          : suggestions_data.useDate.split('-')[1] + "/" +
                          suggestions_data.useDate.split('-')[2] + "/"
                          + suggestions_data.useDate.split('-')[0],
                        modifiedBy: suggestions_data.modifiedBy,
                      }
                    }
                    translationUnitSegmentsLocal.push(translationUnitRequest);
                    if (((text_part.segments.length-1) === segmentsIndex)) {
                      let translationMemoryIndividualObj = {
                        UserID: sessionStorage.getItem('userId').toUpperCase(),
                        TextID: data.textid,
                        TextType_SHORT: data.objNameShort,
                        Part_ID: text_part.partId,
                        TranslationUnitRequestList: [...translationUnitSegmentsLocal],
                      }
                      translationMemoryPayloadLocal.push(translationMemoryIndividualObj);
                       translationUnitSegmentsLocal = [];
                    }
 
                    if ((suggestions_data.translation === null) || (suggestions_data.translation === '')) {
                      segements_data.translated_text = '';
                      segements_data.targetTextLength = 0;
                      segements_data.baseScore = 0;
                      segements_data.textChanged = suggestions_data.textChanged
                    } else if ((suggestions_data.translation !== null) && (suggestions_data.translation !== undefined)
                     && (suggestions_data.translation !== '')){
                     if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1
                      && segmentsIndex === 0 && textPartIndex === 0 && textIndex === 0
                     ) {
                      setSaveIdOfTextArea((prevState) => ({
                        ...prevState,
                        translation: suggestions_data.translation,
                      }));
                    }
                      segements_data.translated_text = suggestions_data.translation
                      // creating a variable for translated text length and storing the individual
                      // segment length value under each segment object
                      if ((text_part.segments.length - 1) !== segements_data.segementID) {
                          if (suggestions_data.translation.length !== 0) {
                            segements_data.targetTextLength = suggestions_data.translation.length + 1;
                          } else {
                            segements_data.targetTextLength  = 0;
                          }
                      } else if ((text_part.segments.length - 1) === segements_data.segementID) {
                          segements_data.targetTextLength = suggestions_data.translation.length;
                      }
                      segements_data.baseScore = suggestions_data.baseScorce;
                      segements_data.textChanged = suggestions_data.textChanged
                    }
                  }
                  if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1) {
                    if ((segements_data.segementID === 0) && (translationSuggestionsIndex === (segements_data.translationsSuggestions.length - 1))) {
                      if (isConfirmButtonClicked === undefined){
                        setSelectedSegment(prevState => {
                          return {...prevState, translationSuggestionLength: validSuggestionsCount}
                        })
                      }
                    }
                  }
              })
          })
      } )
  })

  productsTranslationDetails.forEach((productTranslation, translationIndex) => {  // 3 text object
    productTranslation.textObjectParts.forEach((textPartInfo, textPartIndex) => {
      editedTextDetails_segments.forEach((segmentsTranslation, segmentsIndex) => {
        if ((segmentsTranslation.textId === textPartInfo.textId)
        && (textPartInfo.textPartName === segmentsTranslation.textPartName) ) {
          textPartInfo.textSegments = [...textPartInfo.textSegments, segmentsTranslation];
        }
      })
    })
  })

  if(sitsContext.editorSearchPageLocationData.selectedTextData.length === 1
    && isConfirmButtonClicked === undefined
  ) {
    setShowHistoryTab(true);
    setLoadMatchesSuggestions(true);
  }


  if (segmentsChanges.length === 0) {
    setSegmentsChanges(segmentsChanges => [...segmentsChanges, ...segmentsChangesLocal])
  } else if (segmentsChanges.length !== 0) {
    setSegmentsChanges(segmentsChanges.map(
      individualSegmentDetails => segmentsChangesLocal.find(newIndividualSegmentDetails => (
        newIndividualSegmentDetails.textId === individualSegmentDetails.textId
        && newIndividualSegmentDetails.partId === individualSegmentDetails.partId
        && newIndividualSegmentDetails.segmentId === individualSegmentDetails.segmentId
      )) || individualSegmentDetails
    ));
  }

  if (editedTextDetails.length === 0) {
    setEditedTextDetails(editedTextDetails => [...editedTextDetails, ...productsTranslationDetails]);
  } else if (editedTextDetails.length > 0) {
    const latestEditedTextDetails = editedTextDetails.map((latestTextDetails) => {
       if (productsTranslationDetails[0].textId === latestTextDetails.textId) {
        return productsTranslationDetails[0];
       }
       return latestTextDetails;
    })
    const latestFinalizedEditedTextDetails = JSON.parse(JSON.stringify(latestEditedTextDetails));
    setEditedTextDetails(latestFinalizedEditedTextDetails);
    // setEditedTextDetails(editedTextDetails.map(
    //   individualTextPayload => productsTranslationDetails.find(
    //     individualTextPayload1 => (
    //       individualTextPayload1.textId === individualTextPayload.textId))
    //   || individualTextPayload
    // ));
  }

 if(translationMemoryPayload.length === 0){
 setTranslationMemoryPayload(translationMemoryPayload => [...translationMemoryPayload, ...translationMemoryPayloadLocal]);
 } else if (translationMemoryPayload.length > 0) {
  setTranslationMemoryPayload(translationMemoryPayload.map(
    indText => translationMemoryPayloadLocal.find(indText1 => (
      indText1.TextID === indText.TextID && indText1.Part_ID === indText.Part_ID
    )) || indText
  ));
 }

// editvalue usestate is getting updated with the either new text id object / updated existing text id
// information
 if (editValueLocal.length === 0) {
  editValueLocal = [...response.data];
  setEditValue(response.data);
}else if(editValueLocal.length > 0 && isConfirmButtonClicked === undefined){
  editValueLocal = editValueLocal.map(individualText => response.data.find(
    individualText1 => individualText1.textid === individualText.textid) || individualText)
  setEditValue(editValueLocal);
} else if (editValueLocal.length > 0 && isConfirmButtonClicked !== undefined) {
  editValueLocal = editValueLocal.map(individualText => response.data.find(
    individualText1 => individualText1.textid === individualText.textid) || individualText)
  setEditValue(editValue.map(individualText => 
    response.data.find(individualText1 => individualText1.textid === individualText.textid)
    || individualText));
}
      res.forEach((textData, textIndex) => {
          textData.textpart.forEach((textPartData, textPartIndex) => {
            textPartData.segments.forEach((segmentData, indexSeg) => {
              if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1
                && isConfirmButtonClicked === undefined) {
                if (selectedIdUpdated === false && segmentData.segment !== null
                  && segmentData.segment !== "" && segmentData.segment.length !== 0) {
                    let idName = "editableField-"+ textData.textid +  "-" + textPartData.partId + "-" + textPartData.partName + "-" + segmentData.segementID;
                    setSaveIdOfTextArea((prevState) => ({
                      ...prevState,
                      id: `${idName}-${indexSeg}`,
                    }));
                    selectedIdUpdated = true;
                  }
                }
              if (textPartData.segments.length > 1) {
                charCountForMultipleSegments += segmentData.targetTextLength;
              }
              // Below if block gets executed at the end of the segments array
              if ((indexSeg + 1) === textPartData.segments.length){
              if ((selectedSegmentLocal.textId === textData.textid)
               && (selectedSegmentLocal.partName === textPartData.partName)
                && (selectedSegmentLocal.partId === textPartData.partId.trimStart())){
                  if (textPartData.segments.length === 1) {
                    selectedMetaData = {
                      objectType: textData.objectType,
                      category: textData.category,
                      description: (textData.description !== null && textData.description !== undefined) ? textData.description : null,
                      name: textData.name,
                      type: textPartData.partName,
                      mainProductType: (textData.mainProductType !== null && textData.mainProductType !== undefined) ? textData.mainProductType : null,
                      updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn,
                      charCount: spaceAfterhundreds(segmentData.targetTextLength) + " / " + spaceAfterhundreds(textPartData.maxCharCount),
                      usedin: (textData.usedIn !== null && textData.usedIn !== undefined) ? textData.usedIn : null
                    }
                  } else if (textPartData.segments.length > 1) {
                    selectedMetaData = {
                      objectType: textData.objectType,
                      category: textData.category,
                      description: (textData.description !== null && textData.description !== undefined) ? textData.description : null,
                      name: textData.name,
                      type: textPartData.partName,
                      mainProductType: (textData.mainProductType !== null && textData.mainProductType !== undefined) ? textData.mainProductType : null,
                      updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn,
                      charCount: spaceAfterhundreds(charCountForMultipleSegments) + " / " + spaceAfterhundreds(textPartData.maxCharCount),
                      usedin: (textData.usedIn !== null && textData.usedIn !== undefined) ? textData.usedIn : null
                    }
                  }
                setMetaDataValue(selectedMetaData);
              }
 
              if (textPartData.segments.length > 1) {
                let multipleSegmentsData = {
                  text_id: textData.textid,
                  part_name: textPartData.partName,
                  part_id: textPartData.partId,
                  segments_details: textPartData.segments,
                  totalCharactersCount: charCountForMultipleSegments,
                }
                localMultiSegmentData = [multipleSegmentsData]
                if (multiSegmentDetails.length === 0){
                  setIsMultiSegmentDetails(multiSegmentDetails => [...multiSegmentDetails, multipleSegmentsData]);
                } else if (multiSegmentDetails.length !== 0) {
                    setIsMultiSegmentDetails(multiSegmentDetails.map(individualText => 
                      localMultiSegmentData.find(
                        individualText1 => individualText1.text_id === individualText.text_id
                      && individualText1.part_id === individualText.part_id)
                      || individualText));
                }
              }
            }
            })
            localMultiSegmentData=[];
            charCountForMultipleSegments = 0;
        })
      });
      if (isConfirmButtonClicked === 'ConfirmClicked' 
      || sitsContext.historyRestoreButtonClicked.restoreFlag === true
      || sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === true)  {
        setIsReRender(true);
      }
      if (sitsContext.historyRestoreButtonClicked.restoreFlag === true) {
        const restoreDataLocal = {
          versionName: null,
          restoreFlag: false,
          isRestoreButtonLoading: false,
        };
        sitsContext.updateHistoryRestoreButtonClicked(restoreDataLocal);
      }

      if (sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === true) {
        const numberOfItemsAffectedLocalData = {
          openNumberOfItemsAffectedPopup: false,
          numberOfItemsAffectedFlag: false,
          isSegmentsInfoThere: null,
          isRevertChangesLoading: false,
        };
        sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
      }
      if (isConfirmButtonClicked === 'ConfirmClicked') {
        setTriggerForwardCheck(true);
      }
  } else if (response.response.status !== 200 || response.response.status >= 400) {
    sitsContext.updateEditorPageStatusButton('loadPreviousData');
    navigate(`/${tab_sel}page `, {state: {path: `${selectedtabpath}page`, buttonClicked: 'error', correlationId: response.response.data.CorrelationID}} ); 
  }
  if (editorTableScrollPosition !== null && isConfirmButtonClicked !== undefined) {
    const editorTableElement = document.getElementById('editorContainer');
    if (editorTableElement !== undefined) {
      setTimeout(() => {
        editorTableElement.scrollTo(0, editorTableScrollPosition);
      }, 100);
    }
  }
  if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1
    && isConfirmButtonClicked === undefined
  ) { 
    setCallConnectedProducts(true);
    setSelectedSegment(prevState => {
            return {...prevState, initialRendering: false, textRowChanged: true}
    })
  }
  if (isConfirmButtonClicked === undefined) {
    setIsLoading(false);
   if (response.data[0].isTmAvailable === false) {
    setErrorScenarioNumber(7);
    setErrorMessage(errorHeader.errorOnUpdatingTMHeader);
    setEnableErrorHandler(true);
   }
  }
};


useEffect(() => {
  if (editedTextDetails.length !== 0 && editValue.length !== 0 && (initialCheck === false)) {
    if (sitsContext.editorSearchPageLocationData.selectedTextData.length === 1) {
      editValue.forEach(textData => {
        textData.textpart.forEach(textPartData => {
          if (textPartData.partId === selectedSegment.partId
            && textPartData.partName === selectedSegment.partName
          ) {
            checkCharacterOverflowHandler(textData, textPartData);
          }
        })
      }
      )
      setInitialCheck(true);
    }
  }
},[editedTextDetails, editValue, initialCheck]);

useEffect(() => {
  if (triggerForwardCheck === true) {
    editedTextDetails.forEach((textDetails) => {
     if (textDetails.textId === selectedTextDetails.textId) {
      if (textDetails.status.toLowerCase() === 'translated') {
        loadNewTextsHandler();
        setTriggerForwardCheck(false);
      }
     }
    })
  }

}, [triggerForwardCheck, editedTextDetails]);

const loadNewTextsHandler = () => {
  let newTextIds = [];
  editValue.forEach((textDetails) => {
   if (textDetails.textid !== selectedTextDetails.textId) {
    if (textDetails.category !== undefined) {
      if (textDetails.textpart[0].status.toLowerCase() === "new") {
        newTextIds.push(textDetails.textid);
        textDetails.category = undefined;
      }
    }
   } 
  })
  getLatestDetailsForNewTexts(newTextIds);
};

const getLatestDetailsForNewTexts = (newTextIds) => {
    newTextIds.forEach(async (newId) => {
      const index = loadFewTextsIds.findIndex(
        textIdPayloadDetails => textIdPayloadDetails.textid === newId
      )
      if (index !== -1) {
        await navigateToEditPage([loadFewTextsIds[index]], getEditorData);
      }
  })
};


useEffect(() => {
  if (callConnectedProducts) {
    const connectedProductsPayload = {
      textId: selectedSegment.textId,

      objNameShort:
        selectedSegment.objShort,

      localeCode: selectedPopupValue.selectedlangvalue,
    };

    setCallConnectedProducts(false);

    getConnectedProductsInfo(
      connectedProductsPayload,
      handleConnectedProdInfoResHandler
    );
  }
}, [callConnectedProducts]);

const handleConnectedProdInfoResHandler = (res) => {
  let connectedProdRes = res.data;
  if (res.status === 200) {
    if (typeof res.data !== "undefined") {
      connectedProdRes.itemsConnected.forEach((infoData, index) => {
        const itemNumberNew = infoData.itemNumber
        const productTypeCapitalized = infoData.productType[0].toUpperCase() + infoData.productType.slice(1).toLowerCase();
        infoData.itemNumber = itemNumberNew.toString().replace(/.{3}(?!$)/g, '$&.');
        infoData.productType = productTypeCapitalized;
       })
       connectedProdRes.textId = selectedSegment.textId;
     } else if (typeof res.data === "undefined") {
       connectedProdRes = [];
       connectedProdRes.textId = selectedSegment.textId;
     }
   } else {
    connectedProdRes = [];
    connectedProdRes.textId = selectedSegment.textId;
   }
   sitsContext.updateConnectedProdResData(connectedProdRes);
   setSelectedSegment(prevState => {
    return {...prevState, textRowChanged: false}
   })
}

useEffect(() => {
  if (loadMatchesSuggestions === true) {
    let matchesSuggestionPayload = {
      searchText: segmentToGetMatches,
      targetLocaleCode: selectedPopupValue.selectedlangvalue,
      sourceLocaleCode: navigateEditPagePayload[0].source_locale_code,
    };
    let matchesPayload = [];
    matchesPayload.push(matchesSuggestionPayload);
    setSelectedSegment((prevState) => ({
      ...prevState,
      initialRendering: true,
    }))
    getMatchesSuggestions(matchesPayload, matchesSuggestionCallbackHandler);
  }
},[loadMatchesSuggestions]);

const matchesSuggestionCallbackHandler = (response) => {
   let matchesCount = 0;
   if (response.data.length !== 0) {
    response.data.forEach((matchesSuggestion) => {
      matchesSuggestion.textId = selectedSegment.textId;
      matchesSuggestion.partId = selectedSegment.partId;
      matchesSuggestion.partName = selectedSegment.partName;
      matchesSuggestion.segmentId = selectedSegment.segmentId;
      if (matchesSuggestion.translation !== null && matchesSuggestion.translation !== "") {
        matchesCount = matchesCount + 1;
      }
    })
   }
   sitsContext.updateMatchesSuggestionsCount(matchesCount);
   sitsContext.updateSegmentsDetails(response.data);
   setLoadMatchesSuggestions(false);
   sitsContext.updateChangeMatchesDetails(true);
};

useEffect(() => {
  if (sitsContext.segmentsDetails.length !== 0) {
    let validSuggestionsLength = 0;
    sitsContext.segmentsDetails.forEach((suggestionData, index) => {
      if ((suggestionData.translation !== null && suggestionData.translation !== "")) {
        validSuggestionsLength = validSuggestionsLength + 1;
      }
    })
    setSelectedSegment((prevState) => ({
      ...prevState,
      translationSuggestionLength: validSuggestionsLength,
      initialRendering: false,
    }))
  }
}, [sitsContext.segmentsDetails]);

useEffect(() => {
  if (showHistoryTab === true) {
    historyTabPayload = {
      ...historyTabPayload,
      textid: selectedSegment.textId,
      source_locale_code: navigateEditPagePayload[0].source_locale_code,
      target_locale_code: selectedPopupValue.selectedlangvalue,
      object_type_short: selectedSegment.objShort,
    }
    getHistoryInfo(historyTabPayload, historyTabCallbackHandler)
  }
}, [showHistoryTab]);

const calculateHistoryTabCount = (historyResponse) => {
  let historyCount = 0;
  const historyObjPropLength = Object.keys(historyResponse);
  for (let i in historyObjPropLength) {
    let historyObjKey = Object.keys(historyResponse)[i];
    if (historyResponse[historyObjKey] !== null) {
      historyCount++;
    }
  }
  return historyCount;
};

const historyTabCallbackHandler = (historyRes) => {
  setShowHistoryTab(false);
  if (historyRes.status === 200) {
    let historyResData = [];
    const historyTabCount = calculateHistoryTabCount(historyRes.data);
    historyResData.push(historyRes.data);
    if ((historyResData[0].currentVersion !== null && historyResData[0].currentVersion !== undefined)
      || (historyResData[0].draftVersion !== null && historyResData[0].draftVersion !== undefined)) {
        sitsContext.updateHistoryTabData(true, historyResData, historyTabCount);
    } else {
        sitsContext.updateHistoryTabData(false, [], historyTabCount);
    }
  } else if (historyRes.response.status !== 200 && historyRes.response.status >= 400) {
    sitsContext.updateHistoryTabData(false, [], 0);
  }
};

useLayoutEffect(() => {
    let el = document.getElementById(saveIdOfTextArea.id);
    if ((saveIdOfTextArea.id !== null || (matchesSuggestionSelected === true))
      && ((el !== undefined) && (el !== null))){
    el.focus();
      if (el) {
        el.style.height = "5px";
        el.style.height = (el.scrollHeight) + "px";
        el.style.overflow = 'hidden';
        if ((saveIdOfTextArea.translation !== null) && saveIdOfTextArea.translation.length >= 1) {
          el.setSelectionRange(saveIdOfTextArea.translation.length, saveIdOfTextArea.translation.length);
        }
      }
    }
  
  }, [saveIdOfTextArea])

  useEffect(() => {
    if (editedTextDetails.length > 0) {
      disableReleaseButtonHandler(editedTextDetails);
    }
  }, [editedTextDetails]);

  const disableReleaseButtonHandler = (statusDetails) => {
    let textStatusDetails = [];
    textStatusDetails = statusDetails.filter((textData) => {
      return textData.status.toLowerCase() === sitsContext.actionToStatusMapping.confirm.toLowerCase()
    });
    if (textStatusDetails.length > 0) {
      props.onDisableReleaseHandler(false);
    } else if (textStatusDetails.length === 0) {
      props.onDisableReleaseHandler(true);
    }
  };

  const updateSelectedTextStatusHandler = (textId, textStatus) => {
    let statusChanged = false;
    const updatedSelectedTextDetails = sitsContext.selectedtextsforbulkedit.map((textDetails) => {
      if (textDetails.textid.split('-')[0] === textId
      && textDetails.textid.split('-')[1] !== textStatus.toLowerCase()) {
        statusChanged=true;
        return {
          ...textDetails,
          textid: `${textDetails.textid.split('-')[0]}-${textStatus}`
        }
      }
      return {
        ...textDetails,
      }
    })
    if (statusChanged === true) {
      sitsContext.updateSelectedtextsforbulkedit(updatedSelectedTextDetails);
    }
  }

  useLayoutEffect(() => {
    if (matchesSuggestionSelected === true) {
      let selectedData = {
        textId: sitsContext.selectedSuggestionMatchesDetails.textId,
        partId: sitsContext.selectedSuggestionMatchesDetails.partId,
        partName: sitsContext.selectedSuggestionMatchesDetails.partName,
        segmentId: sitsContext.selectedSuggestionMatchesDetails.segmentId
      }
      setSelectedTextDetails(selectedData);
      setEditValue(editValue.map(textPartData => {
        textPartData.textpart.forEach(segmentsData => {
          if ((sitsContext.selectedSuggestionMatchesDetails.textId === textPartData.textid)
            && (sitsContext.selectedSuggestionMatchesDetails.partId === segmentsData.partId)
            && (sitsContext.selectedSuggestionMatchesDetails.partName === segmentsData.partName)) {
              if (sitsContext.autoSaveEnabled === true) {
                if (segmentsData.status.toLowerCase()
                  !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
                  segmentsData.status = sitsContext.actionToStatusMapping.autosave;
                }
              }
          }
          segmentsData.segments.map((singleSegmentData) => {
            if ((sitsContext.selectedSuggestionMatchesDetails.textId === textPartData.textid)
            && (sitsContext.selectedSuggestionMatchesDetails.partId === segmentsData.partId)
            && (sitsContext.selectedSuggestionMatchesDetails.partName === segmentsData.partName)
            && (sitsContext.selectedSuggestionMatchesDetails.segmentId === singleSegmentData.segementID)){
              singleSegmentData.translated_text = sitsContext.selectedSuggestionMatchesDetails.translationText;
              singleSegmentData.baseScore = sitsContext.selectedSuggestionMatchesDetails.translationAccuracy;
              singleSegmentData.textChanged = false;
              return {
                ...singleSegmentData,
                translated_text: sitsContext.selectedSuggestionMatchesDetails.translationText,
                baseScore:sitsContext.selectedSuggestionMatchesDetails.translationAccuracy,
                textChanged: false,
              };
            }
            return {...singleSegmentData};
          })
          return {...segmentsData}
        })
        return {...textPartData, testing: 'one'};
      }
      ))
      disableReleaseButtonHandler(editedTextDetails);
      setTypedText(sitsContext.selectedSuggestionMatchesDetails.translationText);
      setIsReRender(false);
      setMatchesSuggestionSelected(false);
    }
  }, [matchesSuggestionSelected])

  // Use effect to handle the changes if user selects a suggestion from matches tab, top editor
  // table need to reflect that changes

  useLayoutEffect(() => {
    if ((sitsContext.selectedSuggestionMatchesDetails.textId !== null) && sitsContext.isUseSuggestionClicked) {
      let target_text_length;
  
      // Below code can be a separate function
      editedTextDetails.forEach((editedTextData) => {
        editedTextData.textObjectParts.forEach((textPartInfo) => {
          if ((editedTextData.textId === sitsContext.selectedSuggestionMatchesDetails.textId)
            && (textPartInfo.textPart === sitsContext.selectedSuggestionMatchesDetails.partId)
            && (textPartInfo.textPartName === sitsContext.selectedSuggestionMatchesDetails.partName)) {
              if (sitsContext.autoSaveEnabled === true) {
                if (editedTextData.status.toLowerCase()
                  !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
                  editedTextData.status = sitsContext.actionToStatusMapping.autosave;
                }
              }  
              textPartInfo.textSegments.forEach((segmentedRelatedData) => {
               if (segmentedRelatedData.segmentId === sitsContext.selectedSuggestionMatchesDetails.segmentId) {
                segmentedRelatedData.translatedSegment = sitsContext.selectedSuggestionMatchesDetails.translationText;
                segmentedRelatedData.baseScore = sitsContext.selectedSuggestionMatchesDetails.translationAccuracy;
                segmentedRelatedData.textChanged = true;
              }
              })
              target_text_length = sitsContext.selectedSuggestionMatchesDetails.translationText.length;
            }
        })
      })
      let checkIfSegmentIsThere = false;
      // Below code can be a separate function
      if (segmentsChanges.length !== 0) {
        segmentsChanges.forEach((segmentChangeData) => {
          if ((segmentChangeData.textId === sitsContext.selectedSuggestionMatchesDetails.textId)
          && (segmentChangeData.partId === sitsContext.selectedSuggestionMatchesDetails.partId)
          && (segmentChangeData.partName === sitsContext.selectedSuggestionMatchesDetails.partName)
          && (segmentChangeData.segmentId === sitsContext.selectedSuggestionMatchesDetails.segmentId )) {
            checkIfSegmentIsThere = true;
            segmentChangeData.targetText = sitsContext.selectedSuggestionMatchesDetails.translationText;
          }
        })
      }

      let updatedSegmentInfo = {
        textId: sitsContext.selectedSuggestionMatchesDetails.textId,
        partId: sitsContext.selectedSuggestionMatchesDetails.partId,
        partName: sitsContext.selectedSuggestionMatchesDetails.partName,
        segmentId: sitsContext.selectedSuggestionMatchesDetails.segmentId,
        sourceText: sitsContext.selectedSuggestionMatchesDetails.sourceText,
        targetText: sitsContext.selectedSuggestionMatchesDetails.translationText,
      }

      if (checkIfSegmentIsThere === false) {
        if (segmentsChanges.length === 0) {
          setSegmentsChanges(segmentsChanges => [...segmentsChanges, updatedSegmentInfo]);
         } else if (segmentsChanges.length !== 0) {
          let segmentsChangesInfo = segmentsChanges.map((indSegmentChange) => {
            if (indSegmentChange.sourceText === sitsContext.selectedSuggestionMatchesDetails.sourceText) {
              segmentChangesFlag = true;
              return {...indSegmentChange, targetText: sitsContext.selectedSuggestionMatchesDetails.translationText}
            }
            return indSegmentChange;
          })
          if (segmentChangesFlag === true) {
            setSegmentsChanges(segmentsChangesInfo);
            segmentChangesFlag=false;
          } else if (segmentChangesFlag === false) {
            setSegmentsChanges(segmentsChanges => [...segmentsChanges, updatedSegmentInfo]);
          }
         }
      }
      

      //Duplicate code for multiple segmentDetails update
      multiSegmentDetails.forEach((multiOuterSegment) => {
        if ((multiOuterSegment.text_id === sitsContext.selectedSuggestionMatchesDetails.textId)
          && (multiOuterSegment.part_id === sitsContext.selectedSuggestionMatchesDetails.partId)
        && (multiOuterSegment.part_name === sitsContext.selectedSuggestionMatchesDetails.partName)) {
          target_text_length = 0;
          multiOuterSegment.segments_details.forEach((individualSegments) => {
                if ((multiOuterSegment.segments_details.length - 1)
                  !== sitsContext.selectedSuggestionMatchesDetails.segmentId) {
                  if (individualSegments.segementID
                    === sitsContext.selectedSuggestionMatchesDetails.segmentId) {
                    individualSegments.translated_text
                    = sitsContext.selectedSuggestionMatchesDetails.translationText;
                    if (sitsContext.selectedSuggestionMatchesDetails.translationText.length !== 0) {
                      individualSegments.targetTextLength
                      = sitsContext.selectedSuggestionMatchesDetails.translationText.length + 1;
                    } else {
                      individualSegments.targetTextLength = 0;
                    }
                  }
                } else if ((multiOuterSegment.segments_details.length - 1)
                  === sitsContext.selectedSuggestionMatchesDetails.segmentId) {
                  if (individualSegments.segementID
                    === sitsContext.selectedSuggestionMatchesDetails.segmentId) {
                    individualSegments.translated_text
                    = sitsContext.selectedSuggestionMatchesDetails.translationText;
                    individualSegments.targetTextLength
                    = sitsContext.selectedSuggestionMatchesDetails.translationText.length;
                  }
                }
                if (individualSegments.targetTextLength !== undefined) {
                  target_text_length += individualSegments.targetTextLength; 
                }
          })
          multiOuterSegment.totalCharactersCount = target_text_length;
        }
      });
      setIsReRender(true);
      setMatchesSuggestionSelected(true);
      setMetaDataValue(prevState => {
        return {...prevState, charCount: spaceAfterhundreds(target_text_length)+ " / " + spaceAfterhundreds(metaDataValue.charCount.split('/')[1])}
      })
      const editDetailsForCharacterCheck = editValue.filter((editDetails) => {
        if (editDetails.textid === sitsContext.selectedSuggestionMatchesDetails.textId) {
          return {
            ...editDetails
          }
        }
      })
      if (editDetailsForCharacterCheck.length !== 0) {
        const textPartDetailsForCharacterCheck
      = editDetailsForCharacterCheck[0].textpart.filter((textPartDetails) => {
        if (textPartDetails.partId === sitsContext.selectedSuggestionMatchesDetails.partId) {
          return {
            ...textPartDetails
          }
        }
      })
      checkCharacterOverflowHandler(editDetailsForCharacterCheck[0], textPartDetailsForCharacterCheck[0]);
      }
      
    // Error check if we have paragraph mismatch error / mandatory field empty error
    if (sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
      || sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true) {
        const confirmButtonFlag = false;
        errorCheckOnConfirmHandler(confirmButtonFlag);
    }
    
    // Check if there are any duplicate part name exists for long, medium text or value object
    if (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true) {
      if (selectedSegment.objShort.toLowerCase() === "lngtxt"
        || selectedSegment.objShort.toLowerCase() === "mdmtxt"
        || selectedSegment.objShort.toLowerCase() === "value") {
          duplicateTranslationTextRemoveHandler(
            sitsContext.selectedSuggestionMatchesDetails.textId,
            sitsContext.selectedSuggestionMatchesDetails.partName,
            undefined
          );
        }
      }
      sitsContext.updateUseSuggestionClicked(false);
    }
  }, [sitsContext.selectedSuggestionMatchesDetails])

  useImperativeHandle(ref, () => ({
    saveTranslationHandler () {
      if ((confirmedTexts.length) === (editValue.length)) {
        let releasedTexts = editedTextDetails.filter((editedTextData) => {
          if (confirmedTexts.includes(editedTextData.textId)) {
            return {
              ...editedTextData,
              previousStatus: sitsContext.actionToStatusMapping.confirm,
              status: sitsContext.actionToStatusMapping.released,
              user: sessionStorage.getItem('userId'),
            }
          }
        });
        releasedTexts.forEach((editedTextData) => {
          editedTextData.previousStatus = sitsContext.actionToStatusMapping.confirm
          editedTextData.status = sitsContext.actionToStatusMapping.released
          editedTextData.user = sessionStorage.getItem('userId')
        })
        setIsLoading(true);
        saveTranslation(releasedTexts, translationSavedHandler);
      } else {
        setDisplayReleaseModal(true);
      }
    },
    async releasetextHandler () {
      if ((confirmedTexts.length) === (sitsContext.editorSearchPageLocationData.selectedTextData.length)){
        props.loadingOfReleaseButton(true);
        setDisplayReleaseModal(false);
        releaseTranslatedTexts(sitsContext.dataOfRelaseTexts.textIdsToRelease, translationSavedHandler);
      } else if ((confirmedTexts.length) !== (sitsContext.editorSearchPageLocationData.selectedTextData.length)) {
        setDisplayReleaseModal(true);
      }
    },
    autoSaveHandler () {
      if (isConfirmButtonIsClicked !== true) {
        sitsContext.updateEditorBottomLeftHeader('matches');
      }
      if (sitsContext.autoSaveEnabled === true) {
        sitsContext.updateBulkEditbuttonStatus(false);
          if (typedText !== savedText) {
            saveTranslation(editedTextDetails, savedInprogressTranslationCallbackHandler);
          }
          if ((savedText === null)) {
            sitsContext.updateNavigateEditToSearchPageFlag(`${tab_sel}`);
            navigate(`/${tab_sel}page `, {state:{path: `${selectedtabpath}page`, buttonClicked: 'close'}});
          } else if ((savedText !== null)) {
            sitsContext.updateNavigateEditToSearchPageFlag(`${tab_sel}`);
            sessionStorage.setItem("function", "closeUpdate");
            navigate(`/${tab_sel}page `, {state:{path: `${selectedtabpath}page`, buttonClicked: 'closeUpdate'}});
            sitsContext.updateEditorPageStatusButton('loadNewData');
          }
    } else if ((sitsContext.autoSaveEnabled === null) || (sitsContext.autoSaveEnabled === false)) {
      sitsContext.updateBulkEditbuttonStatus(false);
      if (changesSavedAtleastOnce === true) {
        sitsContext.updateNavigateEditToSearchPageFlag(`${tab_sel}`);
        sessionStorage.setItem("function", "closeUpdate");
        navigate(`/${tab_sel}page `, {state:{path: `${selectedtabpath}page`, buttonClicked: 'closeUpdate'}});
        // sitsContext.updateEditorPageStatusButton('loadNewData');
      } else {
        sitsContext.updateNavigateEditToSearchPageFlag(`${tab_sel}`);
        navigate(`/${tab_sel}page `,  {state:{path: `${selectedtabpath}page`, buttonClicked: 'close'}});
      }
    }
  }
  }))


  const toggleEditorReleaseModalHandler = (displayReleaseModalFlag) => {
    setDisplayReleaseModal(displayReleaseModalFlag);
  };

  const releaseButtonhandler = () => {
    props.loadingOfReleaseButton(true);
    releaseTranslatedTexts(sitsContext.dataOfRelaseTexts.textIdsToRelease, translationSavedHandler);
  };

  const translationSavedHandler = (editedResponse) => {
    if (editedResponse.status === 200) {
      setTimeout(() => {
      if (isConfirmButtonIsClicked !== true) {
        sitsContext.updateEditorBottomLeftHeader('matches');
      }
      sitsContext.updateNumberOfReleasedTexts(confirmedTexts);
      sitsContext.updateEditorPageStatusButton('loadNewData');
      sitsContext.updateNavigateEditToSearchPageFlag(`${tab_sel}`);
      sitsContext.updateToastMessage(true, `${confirmedTexts.length} text${confirmedTexts.length > 1 ? 's' : ''} released`);
      navigate(`/${tab_sel}page `, {state: {path: `${selectedtabpath}page`, buttonClicked: 'release'}} );
      sitsContext.updateBulkEditbuttonStatus(false);
      props.loadingOfReleaseButton(false);
    }, 5000);
    } else if ((editedResponse.response.status !== 200 && editedResponse.response.status >= 400)) {
      setIsLoading(false);
      correlationIdEditorPage = editedResponse.response.data.CorrelationID;
      setErrorScenarioNumber(5);
      setErrorMessage(errorHeader.errorOnReleasingTextHeader);
      setEnableErrorHandler(true);
    }
  }

  useEffect(() => {
    if (sitsContext.autoSaveEnabled === true) {
    const autoSave = setInterval(() => {
      setAutoSave(true);
    }, 2 * 1000);
    return () => {
      setAutoSave(false);
      clearInterval(autoSave);
    };
  }
  }, []);

  useEffect(() => {
    if (sitsContext.autoSaveEnabled === true) {
      if (((isAutoSaveCompleted === true || isAutoSaveCompleted === null)) && (typedText !== null)
      && autoSave && typedText !== savedText) {
        setIsAutoSaveCompleted(false);
        let detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
          if (payloadData.textId === selectedSegment.textId) {
            return {...payloadData}
          }
        });
        detialsofConfirmedText.forEach((confirmedTextDetails) => {
          confirmedTextDetails.textObjectParts.forEach((textPartInfo) => {
            textPartInfo.textSegments.forEach((confirmedTextSegmentsDetails) => {
              if (confirmedTextSegmentsDetails.translatedSegment !== null &&
                confirmedTextSegmentsDetails.translatedSegment !== "") {
                  confirmedTextSegmentsDetails.translatedSegment
                  = confirmedTextSegmentsDetails.translatedSegment.trimStart().trimEnd();
                  confirmedTextSegmentsDetails.translatedSegment
                  = confirmedTextSegmentsDetails.translatedSegment.replace(/\s\s+/g, ' ');
                }
              });
          })
        });
        sitsContext.updateAutoSaveHeaderChange(true);
        saveTranslation(detialsofConfirmedText, savedInprogressTranslationCallbackHandler);
        setAutoSave(false); // toggle autosave off
      }
    }
  }, [typedText, autoSave, savedText]);

  const savedInprogressTranslationCallbackHandler = (autoSaveResponse, savedTextId) => {
    if (autoSaveResponse.status === 200) {
      if (autoSaveFail === true) {
        setAutoSaveFail(false);
        props.updateAutoSaveErrorFlag(false);
      }
      updatedOnDate = autoSaveResponse.data[0].updatedon.split('T')[0];
      setIsAutoSaveCompleted(true);
      setSavedText(typedText);
      setEditValue(editValue.map(textPartData => {
        textPartData.textpart.map(segmentsData => {
          if(selectedSegment.textId === textPartData.textid){
            if (sitsContext.autoSaveEnabled === true) {
                if (segmentsData.status.toLowerCase()
                  !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
                  setShowHistoryTab(true);
                  segmentsData.status = sitsContext.actionToStatusMapping.autosave;
                  disableReleaseButtonHandler(editedTextDetails);
                }
              }
            if(segmentsData.updatedBy.toLowerCase()
            !== sessionStorage.getItem('userId').toLowerCase()) {
              segmentsData.updatedBy = sessionStorage.getItem('userId').toUpperCase();
            }
            if (autoSaveResponse.data[0].updatedon.split('T')[0] !== segmentsData.updatedOn) {
              segmentsData.updatedOn = autoSaveResponse.data[0].updatedon.split('T')[0]
            }
          }
        })
        return {...textPartData};
      }
      ))
      if ((metaDataValue.updatedBy.split(' ')[0]) !== sessionStorage.getItem('userId').toLowerCase()) {
        setMetaDataValue(prevState => {
          return {
            ...prevState,
            updatedBy: sessionStorage.getItem('userId').toUpperCase() + " " + updatedOnDate
          }
         })
      } else if ((metaDataValue.updatedBy.split(' ')[1])
      !== autoSaveResponse.data[0].updatedon.split('T')[0]) {
       setMetaDataValue(prevState => {
        return {...prevState, updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn}
       })
      }
      sitsContext.updateAutoSaveHeaderChange(false);
      setEditedTextDetails(editedTextDetails.map((editedText) => {
        if (editedText.textId === selectedSegment.textId) {
          if (editedText.previousStatus !== 'In progress') {
            editedText.previousStatus = 'In progress';
          }
        }
        return {...editedText}
      }))
    } else if (autoSaveResponse.response.status !== 200 && autoSaveResponse.response.status >= 400) {
      setTypedText(null);
      sitsContext.updateAutoSaveHeaderChange(null);
      setIsAutoSaveCompleted(true);
      if (autoSaveFail === false) {
        props.updateAutoSaveErrorFlag(true);
        setErrorScenarioNumber(10);
        setErrorMessage(errorHeader.errorOnAutoSavingHeader);
        setEnableErrorHandler(true);
        correlationIdEditorPage = editedResponse.response.data.CorrelationID;
      }
    }    
  }

  const historyRestoreFuncHandler = () => {
    const restoreDataLocal = {
      isRestoreButtonLoading: true,
    };
    sitsContext.updateHistoryRestoreButtonClicked(restoreDataLocal);
    readyRestorePayload();
  }

  const revertChangesHandler = () => {
    const numberOfItemsAffectedRevertChanges = {
      numberOfItemsAffectedFlag: false,
      isSegmentsInfoThere: null,
      isRevertChangesLoading: true,
    };
    sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedRevertChanges);
    readyRestorePayload();
  };

  const readyRestorePayload = () => {
    let selectedVersionRestoreData = {};
    let updatedVersionRestoreData = {};
    sitsContext.historyDetails.forEach((historyData) => {
      const historyVersionObjKey = Object.keys(historyData);
      historyVersionObjKey.forEach((versionName) => {
        if (sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === false) {
          if (versionName.toLowerCase()
            === sitsContext.historyRestoreButtonClicked.versionName.toLowerCase()) {
              selectedVersionRestoreData = historyData[versionName]
          }
        } else {
          if (versionName.toLowerCase() === 'currentversion') {
            selectedVersionRestoreData = historyData[versionName];
          }
        }
      })
    })
    updatedVersionRestoreData = {
      ...selectedVersionRestoreData,
    }
    delete updatedVersionRestoreData.translatedBy
    if (updatedVersionRestoreData.textValue !== undefined) {
      delete updatedVersionRestoreData.textValue
    }
    updatedVersionRestoreData['user'] = selectedVersionRestoreData.translatedBy;
    updatedVersionRestoreData['textValues'] = selectedVersionRestoreData.textValue;
    let detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
      if (payloadData.textId === selectedTextDetails.textId) {
        return {...payloadData}
      }
    });
    detialsofConfirmedText.forEach((confirmedText) => {
      if (confirmedText.status.toLowerCase() !== "modified") {
        confirmedText.restore = true;
        confirmedText.restoreRequest = updatedVersionRestoreData;
      } else {
        if (sitsContext.autoSaveEnabled === true) {
          if (confirmedText.status.toLowerCase()
            !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
              confirmedText.status = sitsContext.actionToStatusMapping.autosave;
          }
        }
      }
    })
    saveTranslation(detialsofConfirmedText, restoreHistoryCallbackHandler);
  }

  const restoreHistoryCallbackHandler = (response) => {
    const index= navigateEditPagePayload.findIndex(x => x.textid === selectedTextDetails.textId);
    let routingData = [{
      textid: navigateEditPagePayload[index].textid,
      source_locale_code: navigateEditPagePayload[index].source_locale_code,
      target_locale_code: navigateEditPagePayload[index].target_locale_code,
      object_type_short: navigateEditPagePayload[index].object_type_short,
      isParentEnabled: true, 
      }] 
     if (response.status === 200) {
      navigateToEditPage(routingData, getEditorData);
     }
  };

  // Useeffect which gets executed upon clicking on restore link in the history tab
  useEffect(() => {
    if (sitsContext.historyRestoreButtonClicked.restoreFlag === true) {
        let restoreHistoryPopupValidationMessageLocal = {
            ...validationMessage[5].restoreHistoryPopup[0],
        };
        restoreHistoryPopupValidationMessageLocal.validationMessage
        = restoreHistoryPopupValidationMessageLocal.validationMessage.split('\n').join('<br>');
        setValidationErrorMessage(restoreHistoryPopupValidationMessageLocal);
        setValidationErrorPopupEnabled(true);
    } else if (sitsContext.historyRestoreButtonClicked.restoreFlag === false) {
      setValidationErrorPopupEnabled(false);
    }
  }, [sitsContext.historyRestoreButtonClicked])

  useEffect(() => {
    if (sitsContext.paragraphMismatchErrorEditor.openParagraphMismatchErrorPopup === true) {
      let validationMessageLocal =  {
        ...validationMessage[2].paragraphMismatch[0],
      }
      validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        'source count',
        sitsContext.paragraphMismatchErrorEditor.officialLanguageParagraphCount.toString()
      );
      validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        'target count',
        sitsContext.paragraphMismatchErrorEditor.targetLanguageParagraphCount.toString()
      );
      setValidationErrorMessage(prevData => ({
        ...prevData,
        ...validationMessageLocal
      }));
      setValidationErrorPopupEnabled(true);
      setValidationTooltipMessage(toastMsg.numOfParagraphsMismatch);
    } else if ((sitsContext.paragraphMismatchErrorEditor.openParagraphMismatchErrorPopup === false)) {
      setValidationErrorPopupEnabled(false);
    }
  }, [sitsContext.paragraphMismatchErrorEditor])

  useEffect(() => {
    if (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true) {
      let validationMessageLocal = null;
      if (sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.length === 1) {
        validationMessageLocal = {
          ...validationMessage[3].missingSinglePartTranslation[0],
        }
        validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        'textpart',
        sitsContext.textPartEmptyErrorEditor.textPartNameEmpty[0]
      );
      validationMessageLocal.validationHeader
      = validationMessageLocal.validationHeader.replace(
        'textpart',
        sitsContext.textPartEmptyErrorEditor.textPartNameEmpty[0]
      );
      setValidationTooltipMessage(validationMessageLocal.validationMessage);
      } else if (sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.length === 2) {
        validationMessageLocal = {
          ...validationMessage[4].missingTwoPartTranslation[0],
        }
        let matchedData = {};
        sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.forEach((textpartName, textPartEmptyIndex) => {
          matchedData[`textpart${textPartEmptyIndex+1}`] = textpartName
        })
        validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        /\b(?:textpart1|textpart2)\b/gi, matched => matchedData[matched]
      );
      validationMessageLocal.validationHeader
      = validationMessageLocal.validationHeader.replace(
        /\b(?:textpart1|textpart2)\b/gi, matched => matchedData[matched]
      );
      setValidationTooltipMessage(validationMessageLocal.validationMessage);
    } else if (sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.length > 2) {
      validationMessageLocal = {
        ...validationMessage[7].missingMultiplePartTranslation[0],
      }
      let matchedData = {};
      sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.forEach((textpartName, textPartEmptyIndex) => {
        matchedData[`textpart${textPartEmptyIndex+1}`] = textpartName
      })
      validationMessageLocal.validationMessage
    = validationMessageLocal.validationMessage.replace(
      /\b(?:textpart1|textpart2|textpart3)\b/gi, matched => matchedData[matched]
    );
    validationMessageLocal.validationHeader
    = validationMessageLocal.validationHeader.replace(
      /\b(?:textpart1|textpart2|textpart3)\b/gi, matched => matchedData[matched]
    );
    setValidationTooltipMessage(validationMessageLocal.validationMessage);
    }
      if (sitsContext.textPartEmptyErrorEditor.openTextPartEmptyErrorPopup === true) {
        setValidationErrorMessage(prevData => ({
          ...prevData,
          ...validationMessageLocal
        }));
        setValidationErrorPopupEnabled(true);
      } else if (sitsContext.textPartEmptyErrorEditor.openTextPartEmptyErrorPopup === false) {
        setValidationErrorPopupEnabled(false);
      }
    }
  }, [sitsContext.textPartEmptyErrorEditor]);


  // Segments missing Validation business scenario
  useEffect(() => {
    if (sitsContext.segmentsMissingValidation.openSegmentsMissingValidationErrorPopup === true) {
      let validationMessageLocal =  {
        ...validationMessage[0].translationSegmentsMissing[0],
      }
      setValidationErrorMessage(prevData => ({
        ...prevData,
        ...validationMessageLocal
      }));
      setValidationErrorPopupEnabled(true);
      setValidationTooltipMessage(validationMessageLocal.validationMessage);
    } else if ((sitsContext.segmentsMissingValidation.openSegmentsMissingValidationErrorPopup === false)) {
      setValidationErrorPopupEnabled(false);
    }
  }, [sitsContext.segmentsMissingValidation])


  // Duplicate translation business scenario (Executes after confirm button is clicked)
  // Applicable for value & long and medium text (only for name)
  useEffect(() => {
    if (sitsContext.duplicateTranslation.openDuplicateTranslationPopup === true) {
      let validationMessageLocal;
      if (selectedSegment.objShort.toLowerCase() === "lngtxt"
      || selectedSegment.objShort.toLowerCase() === "mdmtxt") {
        validationMessageLocal =  {
          ...validationMessage[9].duplicateNameTranslation[0]
        }
      } else {
        validationMessageLocal =  {
          ...validationMessage[8].duplicateTranslation[0],
        }
        validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        'SOURCE TEXT',
        sitsContext.duplicateTranslation.sourceTextOfDuplicateTranslation
      );
      }

      setValidationErrorMessage(prevData => ({
        ...prevData,
        ...validationMessageLocal
      }));
      setValidationErrorPopupEnabled(true);
      setValidationTooltipMessage(validationMessageLocal.validationMessage);
    } else if ((sitsContext.duplicateTranslation.openDuplicateTranslationPopup === false)) {
      setValidationErrorPopupEnabled(false);
    }
  }, [sitsContext.duplicateTranslation]);


  // Number of items affected message scenario
  useEffect(() => {
     if (sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === true
      && validationErrorPopupEnabled === false
     ) {
      let validationMessageLocal =  {
        ...validationMessage[6].numberOfItemsAffected[0],
      }
      validationMessageLocal.validationMessage
      = validationMessageLocal.validationMessage.replace(
        'numberofitems',
        sitsContext.connectedProdData.itemsConnected.length
      );
      if (sitsContext.numberOfItemsAffected.isSegmentsInfoThere === true) {
        validationMessageLocal.primaryButton = "Revert changes"
      }
      setValidationErrorMessage(prevData => ({
        ...prevData,
        ...validationMessageLocal
      }));
      setValidationErrorPopupEnabled(true);
     } else if (sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === false
      && validationErrorPopupEnabled === true
     ) {
      setValidationErrorPopupEnabled(false);
      const numberOfItemsAffectedLocalData = {
        openNumberOfItemsAffectedPopup: false,
        numberOfItemsAffectedFlag: false,
        isSegmentsInfoThere: null,
        isRevertChangesLoading: false,
      };
      sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
     }
  }, [sitsContext.numberOfItemsAffected, sitsContext.connectedProdData])

  useEffect(() => {
    if (sitsContext.numberOfItemsAffected.numberOfItemsAffectedFlag === true
      && sitsContext.connectedProdData.textId !== null
    && sitsContext.connectedProdData.textId === selectedSegment.textId) {
        if (sitsContext.connectedProdData.itemsConnected.length > 0) {
          const numberOfItemsAffectedLocalData = {
             openNumberOfItemsAffectedPopup: true
          }
          sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
        } else if (sitsContext.connectedProdData.itemsConnected.length === 0) {
          const numberOfItemsAffectedLocalData = {
            openNumberOfItemsAffectedPopup: false,
            numberOfItemsAffectedFlag: false,
            isSegmentsInfoThere: null,
            isRevertChangesLoading: false,
          }
          sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
          saveTranslationChangesHandler(selectedTextDetails.textId)
        }
     }
  }, [sitsContext.numberOfItemsAffected, sitsContext.connectedProdData])


  // Change position of the tooltip message
//   useEffect(() => {
//     if (sitsContext.paragraphMismatchErrorEditor.openParagraphMismatchErrorPopup === true
//       || sitsContext.textPartEmptyErrorEditor.openTextPartEmptyErrorPopup === true
//       || sitsContext.duplicateTranslation.openDuplicateTranslationPopup === true) {
//         const validationErrorTooltipElement = document.getElementById('validationErrorTooltip');
//         const validationErrorIconElement = document.getElementById('validationErrorIcon');
//         if ((validationErrorTooltipElement !== undefined && validationErrorTooltipElement !== null)
//          && (validationErrorIconElement !== undefined && validationErrorIconElement !== null)) {
//           const validIconErrorPos = validationErrorIconElement.getBoundingClientRect();
//           const tooltipErrorPos = validationErrorTooltipElement.getBoundingClientRect();
//           validationErrorTooltipElement.style.width = '17.2rem';
//             const toolTipPosLeftVal = validIconErrorPos.left - tooltipErrorPos.left;
//             const toolTipPosTopVal = validIconErrorPos.top - tooltipErrorPos.bottom; 
//             if (toolTipPosLeftVal > 0 && toolTipPosTopVal > 0) {
//             validationErrorTooltipElement.style.left = `${
//               toolTipPosLeftVal - validationErrorTooltipElement.offsetWidth + 20
//             }px`;
//             validationErrorTooltipElement.style.top = `${
//               toolTipPosTopVal - validationErrorTooltipElement.offsetHeight
//             }px`;
//           }
//    }
//   }
// }, [sitsContext.paragraphMismatchErrorEditor, sitsContext.textPartEmptyErrorEditor, sitsContext.duplicateTranslation]);


// Duplicate translation handler
const duplicateTranslationHandler = (duplicateTranslationTextId, sourceTextDuplicate) => {
  let duplicateTranslationErrorAlreadyExisting = false;
  let bulkDuplicateTranslationData = [];
  let duplicateTranslationErrorLocalDetails = {
    openDuplicateTranslationPopup: true,
    duplicateTranslationErrorFlag: true,
    sourceTextOfDuplicateTranslation: sourceTextDuplicate,
    textId: duplicateTranslationTextId,
  };
  if (sitsContext.duplicateTranslation.duplicateTranslationErrorBulkTextDetails.length !== 0) {
    bulkDuplicateTranslationData
      = [...sitsContext.duplicateTranslation.duplicateTranslationErrorBulkTextDetails];
    bulkDuplicateTranslationData = bulkDuplicateTranslationData.map((bulkTextDetails) => {
      if (bulkTextDetails.textId === duplicateTranslationTextId) {
        return {
          ...bulkTextDetails,
          ...duplicateTranslationErrorLocalDetails
        }
        duplicateTranslationErrorAlreadyExisting = true;
      }
      return {
        ...bulkTextDetails
      }
    })
    if (duplicateTranslationErrorAlreadyExisting === false) {
      bulkDuplicateTranslationData.push(duplicateTranslationErrorLocalDetails);
    }
  } else {
    bulkDuplicateTranslationData.push(duplicateTranslationErrorLocalDetails);
  }
  duplicateTranslationErrorLocalDetails = {
    ...duplicateTranslationErrorLocalDetails,
    duplicateTranslationErrorBulkTextDetails: [...bulkDuplicateTranslationData]
  }
  sitsContext.updateDuplicateTranslation(duplicateTranslationErrorLocalDetails);
};

  // Business scenarios checking
  const errorCheckOnConfirmHandler = (confirmButtonFlag, updatedEditedTextData) => {
    let mandatoryFieldOfObjectType = gObjectTypeValues.find((objectType) => objectType.obj_short_name === selectedSegment.objShort);
    
    // Variables that are needed initialized
    let mandatoryTextPart = [];
    let updatedparagraphCompleteText;
    let enteredAllParagraphData = {};
    let enteredParagraphData = [];
    let source_paragraphText = "";
    let target_paragraphText = "";
    let enteredDataObjKey = [];
    let textPartEmptyError = [];
    let textPartWholeEmptyCount = 0;
    let paragraphEmptyCount = 0;
    let paragraphMismatchErrorLocalDetails = {
      openParagraphMismatchErrorPopup: false,
      paragraphMismatchErrorFlag: false,
      officialLanguageParagraphCount: 0,
      targetLanguageParagraphCount: 0,
      textId: null,
    }
    let textPartEmptyErrorData = {
      openTextPartEmptyErrorPopup: false,
      textPartEmptyErrorFlag: false,
      textPartNameEmpty: [],
      officialLanguage: '',
   }
   let bulkTextPartEmptyErrorData = [];
   let bulkParagraphMismatchErrorData =[];
   let detialsofConfirmedText;
   
   // Creating an array which contains name of mandatory text part
   if (mandatoryFieldOfObjectType.textParts !== undefined) {
    mandatoryFieldOfObjectType.textParts.forEach((singleTextPart) => {
     mandatoryTextPart.push(singleTextPart.partName.toLowerCase());
    })
  }
   
   // Filtering an selected text object from the list of array of objects in which each object has info
   // for a text
   if (updatedEditedTextData !== undefined) {
    detialsofConfirmedText = updatedEditedTextData.filter((payloadData) => {
      if (payloadData.textId === selectedTextDetails.textId) {
        return {...payloadData}
      }
    });
   } else {
    detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
      if (payloadData.textId === selectedTextDetails.textId) {
        return {...payloadData}
      }
    });
   }

   detialsofConfirmedText.forEach((confirmedTextPartData) => {
    confirmedTextPartData.textObjectParts.forEach((textPartInfo) => {
      if (textPartInfo.sourceCompleteText !== ""
        && textPartInfo.sourceCompleteText !== null) {
          let splitupParagraph = textPartInfo.sourceCompleteText.split("\n");
          if (splitupParagraph.length > 1) {
            updatedparagraphCompleteText = splitupParagraph.filter((paragraphData) => {
              if (paragraphData !== "") {
                return paragraphData
              }
            })
          }
          textPartInfo.textSegments.forEach((confirmedTextSegmentsData, confirmedTextSegmentsIndex) => {
            source_paragraphText = source_paragraphText + confirmedTextSegmentsData.sourceSegment
            + confirmedTextSegmentsData.delimiter;
            if (confirmedTextSegmentsData.translatedSegment !== ""
            && confirmedTextSegmentsData.translatedSegment !== null) {
              target_paragraphText = target_paragraphText + confirmedTextSegmentsData.translatedSegment
              + confirmedTextSegmentsData.delimiter;
            }
            if (confirmedTextSegmentsData.delimiter.includes('\n')) {
              let enteredParagraphDataLocal = {
                sourceParagraph: source_paragraphText,
                targetParagraph: target_paragraphText,
                sourceCompleteTextNonSegmented: textPartInfo.sourceCompleteText
              }
              enteredParagraphData.push(enteredParagraphDataLocal);
              source_paragraphText = "";
              target_paragraphText = "";
            } else if (confirmedTextSegmentsData.delimiter === ""
            && (confirmedTextSegmentsIndex === (textPartInfo.textSegments.length - 1))) {
              let enteredParagraphDataLocal = {
                sourceParagraph: source_paragraphText,
                targetParagraph: target_paragraphText,
                sourceCompleteTextNonSegmented: textPartInfo.sourceCompleteText
              }
              enteredParagraphData.push(enteredParagraphDataLocal);
              source_paragraphText = "";
              target_paragraphText = "";
            }
          })
          enteredAllParagraphData[textPartInfo.textPartName] = enteredParagraphData;
          enteredParagraphData=[];
        }
      })
    })
      
      enteredDataObjKey = Object.keys(enteredAllParagraphData);
      enteredDataObjKey.forEach((textPartName) => {
        enteredAllParagraphData[textPartName].forEach((enteredTextInfo) => {
          if (enteredTextInfo.targetParagraph !== ""
          && enteredTextInfo.targetParagraph !== null && enteredTextInfo.sourceParagraph !== ""
          && enteredTextInfo.sourceParagraph !== null) {
            textPartWholeEmptyCount += 1;
          }
          if (enteredAllParagraphData[textPartName].length > 1) {
            if (enteredTextInfo.targetParagraph !== ""
            && enteredTextInfo.targetParagraph !== null) {
              paragraphEmptyCount += 1;
            }
          }  
        })
        if (textPartWholeEmptyCount === 0) {
          if (mandatoryTextPart.includes(textPartName.toLowerCase())) {
            textPartEmptyError.push(textPartName);
            textPartWholeEmptyCount = 0;
          }
        } else {
          if (enteredAllParagraphData[textPartName].length > 1
            && paragraphEmptyCount !== enteredAllParagraphData[textPartName].length
            && paragraphMismatchErrorLocalDetails.paragraphMismatchErrorFlag === false) {
              paragraphMismatchErrorLocalDetails = {
                ...paragraphMismatchErrorLocalDetails,
                openParagraphMismatchErrorPopup: true,
                paragraphMismatchErrorFlag: true,
                officialLanguageParagraphCount: enteredAllParagraphData[textPartName].length,
                targetLanguageParagraphCount: paragraphEmptyCount,
                textId: selectedSegment.textId,
              }
            }
            textPartWholeEmptyCount = 0;
          }
        })
        
      // Text part empty error check
      if (textPartEmptyError.length > 0
        && confirmButtonFlag === true) {
          if (textPartEmptyError.length === 1) {
            let sourceTextData = enteredAllParagraphData[textPartEmptyError[0]];
            textPartEmptyErrorData = {
              ...textPartEmptyErrorData,
              openTextPartEmptyErrorPopup: true,
              textPartEmptyErrorFlag: true,
              textPartNameEmpty: [...textPartEmptyError],
              officialLanguage: sourceTextData[0].sourceCompleteTextNonSegmented, // Needs to add a official language for this text part which is empty
              textId: selectedSegment.textId,
            }
          } else if (textPartEmptyError.length > 1) {
            textPartEmptyErrorData = {
              ...textPartEmptyErrorData,
              openTextPartEmptyErrorPopup: true,
              textPartEmptyErrorFlag: true,
              textPartNameEmpty: [...textPartEmptyError],
              officialLanguage: '', // Needs to add a official language for this text part which is empty
              textId: selectedSegment.textId,
            }
          }
          let textAlreadyExisting = false;
          if (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorBulkTextDetails.length !== 0) {
            bulkTextPartEmptyErrorData
            = [...sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorBulkTextDetails];
            bulkTextPartEmptyErrorData = bulkTextPartEmptyErrorData.map((bulkTextDetails) => {
              if (bulkTextDetails.textId === selectedSegment.textId) {
                return {
                  ...bulkTextDetails,
                  ...textPartEmptyErrorData
                }
                textAlreadyExisting = true;
              }
              return {
                ...bulkTextDetails
              }
            })
            if (textAlreadyExisting === false) {
              bulkTextPartEmptyErrorData.push(textPartEmptyErrorData);
            }
          } else {
            bulkTextPartEmptyErrorData.push(textPartEmptyErrorData);
          }
          textPartEmptyErrorData = {
            ...textPartEmptyErrorData,
            textPartEmptyErrorBulkTextDetails: [...bulkTextPartEmptyErrorData]
          }
          sitsContext.updateTextpartEmptyErrorEditor(textPartEmptyErrorData);
        } else if (textPartEmptyError.length === 0
          && sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true) {
            let updatedBulkTextDetails
            = sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorBulkTextDetails.filter((textDetail) => {
              if (textDetail.textId !== selectedSegment.textId ) {
                return true;
              }
            })
            textPartEmptyErrorData = {
              ...textPartEmptyErrorData,
              openTextPartEmptyErrorPopup: false,
              textPartEmptyErrorFlag: false,
              textPartNameEmpty: [],
              officialLanguage: '', // Needs to add a official language for this text part which is empty
              textId: null,
              textPartEmptyErrorBulkTextDetails: [...updatedBulkTextDetails],
            }
            sitsContext.updateTextpartEmptyErrorEditor(textPartEmptyErrorData);
          }
    
      // Check paragraph mismatch error if textpart empty error is not there
      if (textPartEmptyError.length === 0) {
        if (confirmButtonFlag === true) {
          if ((paragraphMismatchErrorLocalDetails.paragraphMismatchErrorFlag === true
            && sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === false)
            || (paragraphMismatchErrorLocalDetails.paragraphMismatchErrorFlag === true
              && sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true)
            ) {
              let paragraphMismatchErrortextAlreadyExisting = false;
          if (sitsContext.paragraphMismatchErrorEditor.paragraghMismatchErrorBulkTextDetails.length !== 0) {
            bulkParagraphMismatchErrorData
            = [...sitsContext.paragraphMismatchErrorEditor.paragraghMismatchErrorBulkTextDetails];
            bulkParagraphMismatchErrorData = bulkParagraphMismatchErrorData.map((bulkTextDetails) => {
              if (bulkTextDetails.textId === selectedSegment.textId) {
                return {
                  ...bulkTextDetails,
                  ...paragraphMismatchErrorLocalDetails
                }
                paragraphMismatchErrortextAlreadyExisting = true;
              }
              return {
                ...bulkTextDetails
              }
            })
            if (paragraphMismatchErrortextAlreadyExisting === false) {
              // bulkParagraphMismatchErrorData = [...bulkParagraphMismatchErrorData, textPartEmptyErrorData];
              bulkParagraphMismatchErrorData.push(paragraphMismatchErrorLocalDetails);
            }
          } else {
            bulkParagraphMismatchErrorData.push(paragraphMismatchErrorLocalDetails);
          }
          paragraphMismatchErrorLocalDetails = {
            ...paragraphMismatchErrorLocalDetails,
            paragraghMismatchErrorBulkTextDetails: [...bulkParagraphMismatchErrorData]
          }
              sitsContext.updateParagraphMismatchErrorEditor(paragraphMismatchErrorLocalDetails);
            }
          }  else if (paragraphMismatchErrorLocalDetails.paragraphMismatchErrorFlag === false
            && sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true) {
              let updatedParagraphMismatchBulkTextDetails
            = sitsContext.paragraphMismatchErrorEditor.paragraghMismatchErrorBulkTextDetails.filter((textDetail) => {
              if (textDetail.textId !== selectedSegment.textId ) {
                return true;
              }
            })
              paragraphMismatchErrorLocalDetails = {
                ...paragraphMismatchErrorLocalDetails,
                openParagraphMismatchErrorPopup: false,
                paragraphMismatchErrorFlag: false,
                officialLanguageParagraphCount: 0,
                targetLanguageParagraphCount: 0,
                textId: null,
                paragraghMismatchErrorBulkTextDetails: [...updatedParagraphMismatchBulkTextDetails]
              }
              sitsContext.updateParagraphMismatchErrorEditor(paragraphMismatchErrorLocalDetails);
            }
      }
      
      if (textPartEmptyError.length === 0 
        && paragraphMismatchErrorLocalDetails.paragraphMismatchErrorFlag === false) {
          // Segments missing validation
          let isSegmentMissing = false;
          let segmentIDEmpty = null;
          let textAlreadyHasError = false;
          detialsofConfirmedText.forEach((confirmedTextPartData) => {
            confirmedTextPartData.textObjectParts.forEach((textPartInfo) => {
              textPartInfo.textSegments.forEach((segmentsInfo) => {
                if ((segmentsInfo.translatedSegment === null
                 || segmentsInfo.translatedSegment.trimStart().trimEnd() === ""
                ) && !isSegmentMissing) {
                    isSegmentMissing = true;
                    segmentIDEmpty = segmentsInfo.segmentId;
                  } 
              })
            })
          })
          if (confirmButtonFlag === true) {
            if (isSegmentMissing === true) {
              sitsContext.segmentsMissingValidation.segmentsMissingValidationBulkTextDetails.forEach((textData) => {
                if (textData.textId === selectedSegment.textId) {
                  let openErrorPopup = {
                    openSegmentsMissingValidationErrorPopup: true
                  }
                  sitsContext.updateSegmentsMissingValidationEditor(openErrorPopup);
                  textAlreadyHasError = true;
                }
              })

              if (textAlreadyHasError === true) {
                return
              } else {
                let localSegmentsMissingValidationData = {
                  openSegmentsMissingValidationErrorPopup: true,
                  segmentsMissingValidationFlag: true,
                  segmentId: segmentIDEmpty,
                  textId: selectedSegment.textId,
                 }
                let localSegmentsMissingValidationBulkText
                = [...sitsContext.segmentsMissingValidation.segmentsMissingValidationBulkTextDetails,
                  localSegmentsMissingValidationData
                ]
                localSegmentsMissingValidationData = {
                  ...localSegmentsMissingValidationData,
                  segmentsMissingValidationBulkTextDetails: [...localSegmentsMissingValidationBulkText]
                }
                sitsContext.updateSegmentsMissingValidationEditor(localSegmentsMissingValidationData);
                return;
              }
            }

            // Checking Number of affected items business scenario
            if (detialsofConfirmedText[0].objFullName.toLowerCase() === 'benefit summary'
            || detialsofConfirmedText[0].objFullName.toLowerCase() === 'benefit'
            || detialsofConfirmedText[0].objFullName.toLowerCase() === 'long text'
            || detialsofConfirmedText[0].objFullName.toLowerCase() === 'medium text'
            || detialsofConfirmedText[0].objFullName.toLowerCase() === 'colour'
            || detialsofConfirmedText[0].objFullName.toLowerCase() === 'design') {
              checkNumberOfItemsAffectedHandler();
            } else {
              saveTranslationChangesHandler(selectedTextDetails.textId);
            }
          } else {
            if (isSegmentMissing === false) {
              removeSegmentsMissingValidationHandler();
            }
          }
      } else if (confirmButtonFlag === true
        && sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
        && textPartEmptyError.length > 0 ) {
          if (textPartEmptyError.length === 1) {
            let sourceTextData = enteredAllParagraphData[textPartEmptyError[0]];
            textPartEmptyErrorData = {
              ...textPartEmptyErrorData,
              openTextPartEmptyErrorPopup: true,
              textPartEmptyErrorFlag: true,
              textPartNameEmpty: [...textPartEmptyError],
              officialLanguage: sourceTextData[0].sourceCompleteTextNonSegmented, // Needs to add a official language for this text part which is empty
              textId: selectedSegment.textId,
            }
          } else if (textPartEmptyError.length > 1) {
            textPartEmptyErrorData = {
              ...textPartEmptyErrorData,
              openTextPartEmptyErrorPopup: true,
              textPartEmptyErrorFlag: true,
              textPartNameEmpty: [...textPartEmptyError],
              officialLanguage: '', // Needs to add a official language for this text part which is empty
              textId: selectedSegment.textId,
            }
          }
          sitsContext.updateTextpartEmptyErrorEditor(textPartEmptyErrorData);
        } else if (confirmButtonFlag === false
          && sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
          && textPartEmptyError.length > 0 ) {
            let localTextPartEmptyBulkDetails
            = sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorBulkTextDetails.map((bulkData) => {
              if (bulkData.textId === selectedSegment.textId) {
                return {
                  ...bulkData,
                  textPartNameEmpty: [...textPartEmptyError],
                }
              }
              return {
                ...bulkData
              }
            });
            let localTextPartEmpty = {
              textPartNameEmpty: [...textPartEmptyError],
              textPartEmptyErrorBulkTextDetails: [...localTextPartEmptyBulkDetails],
            }
            sitsContext.updateTextpartEmptyErrorEditor(localTextPartEmpty);
          }
  }

  const checkNumberOfItemsAffectedHandler = () => {
    let detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
      if (payloadData.textId === selectedSegment.textId) {
        return {...payloadData}
      }
    });
    
    detialsofConfirmedText.forEach((textPartDetails, textPartDetailsIndex) => {
      if (textPartDetailsIndex === 0) {
        if ((textPartDetails.status.toLowerCase() === 'released') ||
        (textPartDetails.status.toLowerCase() === 'in progress')){
          if (sitsContext.historyDetails !== null && sitsContext.historyDetails.length !== 0) {
            sitsContext.historyDetails.forEach((versionDetails) => {
              if (versionDetails.currentVersion !== null && versionDetails.currentVersion !== undefined) {
                if (versionDetails.currentVersion.status.toLowerCase() === 'released') {
                  checkChangesInText(detialsofConfirmedText, textPartDetails.status.toLowerCase());
                } else {
                  saveTranslationChangesHandler(selectedTextDetails.textId);
                }
              } else {
                saveTranslationChangesHandler(selectedTextDetails.textId);
              }
            })
          } else {
            saveTranslationChangesHandler(selectedTextDetails.textId);
          }
        } else {
          saveTranslationChangesHandler(selectedTextDetails.textId);
        }
      }
    })
  }

  const checkChangesInText = (confirmedTextDetails, statusOfTheText) => {
    let changesInTheText = false;
    if (statusOfTheText === "in progress") {
      changesInTheText = true;
    } else {
      confirmedTextDetails.forEach((textDetails) => {
        textDetails.textObjectParts.forEach((textPartInfo) => {
          textPartInfo.textSegments.forEach((segmentsDetails) => {
            if (segmentsDetails.translatedSegment !== "" || segmentsDetails.translatedSegment !== null) {
              if (segmentsDetails.translatedSegment !== segmentsDetails.initialTranslatedSegment) {
                changesInTheText = true;
              }
            }
          })
        })
      })
    }

    if (changesInTheText === true) {
      numberOfAffectedItemsHandler();
    } else {
      saveTranslationChangesHandler(selectedTextDetails.textId);
    }
  };
  
  const numberOfAffectedItemsHandler = () => {
    let numberOfItemsAffectedLocalData
    sitsContext.historyDetails.forEach((versionDetails) => {
      versionDetails.currentVersion.textValue.forEach((currentVersionDetails) => {
         if (currentVersionDetails.segments !== null
          && currentVersionDetails.segments.length !== 0) {
            numberOfItemsAffectedLocalData = {
              numberOfItemsAffectedFlag: true,
              isSegmentsInfoThere: true,
            }
          } else {
            numberOfItemsAffectedLocalData = {
              numberOfItemsAffectedFlag: true,
              isSegmentsInfoThere: false,
            }
          }
          sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
      })
    })
  }

  const removeSegmentsMissingValidationHandler = () => {
    let localSegmentsMissingValidationData = {
      openSegmentsMissingValidationErrorPopup: false,
      segmentsMissingValidationFlag: false,
      segmentId: null,
      textId: null,
     }
    let localSegmentsMissingValidationBulkText
    = sitsContext.segmentsMissingValidation.segmentsMissingValidationBulkTextDetails.filter((textData) => {
      return textData.textId !== selectedSegment.textId
    });
    localSegmentsMissingValidationData = {
      ...localSegmentsMissingValidationData,
      segmentsMissingValidationBulkTextDetails: [...localSegmentsMissingValidationBulkText]
    }
    sitsContext.updateSegmentsMissingValidationEditor(localSegmentsMissingValidationData);
  };

  const saveChangesContinueButtonhandler = () => {
   if (sitsContext.numberOfItemsAffected.numberOfItemsAffectedFlag) {
    const numberOfItemsAffectedLocalData = {
      openNumberOfItemsAffectedPopup: false,
      numberOfItemsAffectedFlag: false,
      isSegmentsInfoThere: null,
      isRevertChangesLoading: false,
    };
    sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
   }
   
   if (sitsContext.segmentsMissingValidation.segmentsMissingValidationFlag) {
    removeSegmentsMissingValidationHandler();
   }
    saveTranslationChangesHandler(selectedSegment.textId);
  }

  const duplicateTranslationTextRemoveHandler = (textId, partName, isConfirmButtonClicked) => {
    let bulkTextDuplicateTranslationErrorDetails = {
      openDuplicateTranslationPopup: false,
      duplicateTranslationErrorFlag: false,
      sourceTextOfDuplicateTranslation: '',
      textId: null,
    };
    let duplicateTranslationLocalBulkTextDetails;

    if (selectedSegment.objShort.toLowerCase() === "lngtxt"
    || selectedSegment.objShort.toLowerCase() === "mdmtxt") {
      if (partName.toLowerCase() === "name" ||isConfirmButtonClicked) {
        duplicateTranslationLocalBulkTextDetails
        = sitsContext.duplicateTranslation
        .duplicateTranslationErrorBulkTextDetails.filter((errorDetails) => {
          return errorDetails.textId !== textId
        })
      }
    } else {
      duplicateTranslationLocalBulkTextDetails
      = sitsContext.duplicateTranslation
      .duplicateTranslationErrorBulkTextDetails.filter((errorDetails) => {
        return errorDetails.textId !== textId
      })
    }
    if (duplicateTranslationLocalBulkTextDetails !== undefined) {
      bulkTextDuplicateTranslationErrorDetails = {
        ...bulkTextDuplicateTranslationErrorDetails,
        duplicateTranslationErrorBulkTextDetails: [...duplicateTranslationLocalBulkTextDetails]
      }
      sitsContext.updateDuplicateTranslation(bulkTextDuplicateTranslationErrorDetails)
    }
  };

  const checkIfSameTextSemiAutoSubscribeCountriesHandler = (targetTextToBeCompared, textId, partId, sourceText) => {
    let sameTargetSuggestionsDetails = [];
    let parentCountrySameText = false;
    let selectedCountrySameText = false;

    editedTextDetails.forEach((textDetails) => {
      textDetails.textObjectParts.forEach((textPartsDetails) => {
         if ((textDetails.textId === textId) && (textPartsDetails.textPart === partId)) {
           textPartsDetails.textSegments.forEach((segmentsDetails) => {
             if (segmentsDetails.sourceSegment === sourceText) {
              if (segmentsDetails.baseScore > 0 && segmentsDetails.baseScore < 100) {
                return true
              }
             }
           })
         }
      })
    })

    sitsContext.segmentsDetails.forEach((segmentsInfo) => {
      if (segmentsInfo.textId === selectedTextDetails.textId
        && segmentsInfo.partId === selectedTextDetails.partId
        && segmentsInfo.segmentId === selectedTextDetails.segmentId
      ) {
        // segmentsInfo.translationsSuggestions.forEach((suggestionsInfo) => {
           if (segmentsInfo.translation === targetTextToBeCompared) {
               sameTargetSuggestionsDetails.push(segmentsInfo);
           }
        // })
      }
    });

    if (sameTargetSuggestionsDetails.length !== 0) {
      sameTargetSuggestionsDetails.forEach((suggestionsInfo) => {
        const targetLangCode = suggestionsInfo.translatedLangCode
        + '-' + suggestionsInfo.translatedCountryCode;
        if (targetLangCode === selectedPopupValue.selectedlangvalue.toUpperCase()) {
         selectedCountrySameText = true;
        } else {
         parentCountrySameText = true;
        }
      })
    } else {
      return false
    }
    
    if (selectedCountrySameText === false) {
      if (parentCountrySameText === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkIfSameTextSuggestions = (textId, partId, sourceText) => {
    // let isSameText = false;
    // let sameTargetSuggestionsDetails = [];
    let resultOfBaseScoreComparison = false;

    editedTextDetails.forEach((textDetails) => {
      textDetails.textObjectParts.forEach((textPartsDetails) => {
         if ((textDetails.textId === textId) && (textPartsDetails.textPart === partId)) {
           textPartsDetails.textSegments.forEach((segmentsDetails) => {
             if (segmentsDetails.sourceSegment === sourceText) {
              if (segmentsDetails.baseScore > 0 && segmentsDetails.baseScore < 100) {
                resultOfBaseScoreComparison = true
              } else {
                resultOfBaseScoreComparison = false
              }
             }
           })
         }
      })
    })

    return resultOfBaseScoreComparison;


    // sitsContext.segmentsDetails.forEach((segmentsInfo) => {
    //   if (segmentsInfo.textId === selectedTextDetails.textId
    //     && segmentsInfo.partId === selectedTextDetails.partId
    //     && segmentsInfo.segmentId === selectedTextDetails.segmentId
    //   ) {
    //        if (segmentsInfo.translation === targetTextToBeCompared) {
    //            sameTargetSuggestionsDetails.push(segmentsInfo);
    //        }
    //   }
    // });
    // if (sameTargetSuggestionsDetails.length !== 0) {
    //   sameTargetSuggestionsDetails.forEach((suggestionsInfo) => {
    //     const targetLangCode = suggestionsInfo.translatedLangCode
    //     + '-' + suggestionsInfo.translatedCountryCode;
    //     if (targetLangCode.toUpperCase() === selectedPopupValue.selectedlangvalue.toUpperCase()) {
    //      isSameText = true;
    //     }
    //   })
    // } else {
    //   isSameText = false;
    // }
    // return isSameText;
  };


  const saveTranslationChangesHandler = (textIdconfirm) => {
    let newMatchSuggestion = false;
    let detialsofConfirmedTextShadowClone = editedTextDetails.filter((payloadData) => {
      if (payloadData.textId === textIdconfirm) {
        return {...payloadData}
      }
    });
    let detialsofConfirmedText = JSON.parse(JSON.stringify(detialsofConfirmedTextShadowClone));
    let emptyTextCount = 0;
    let textSegmentLength = 0;
    // Below logic is to check if all the segments are empty
    detialsofConfirmedText.forEach((textDetail) => {
      textDetail.textObjectParts.forEach((textPartInfo) => {
        textPartInfo.textSegments.forEach((segmentsInfo, index) => {
          if (segmentsInfo.sourceSegment !== "" && segmentsInfo.translatedSegment === "") {
              emptyTextCount = emptyTextCount + 1;
          }
          if (segmentsInfo.sourceSegment !== "") {
            textSegmentLength += 1;
          }
        })
      })
    })
  // Based on the above logic, we go in to this if condition if all the segments are empty, or go to
  // else if condition if atleast one segment is not empty (Logic is if all segments are empty, then
  // no need save the changes, otherwise call an API to save the changes)
    if (emptyTextCount === textSegmentLength) {
      setConfirmButtonClickedOnEmptyText(true);
      setTimeout(() => {
        setEditValue(editValue.map(textPartData => {
          textPartData.textpart.map(segmentsData => {
            segmentsData.segments.map(individualSegments => {
              individualSegments.textChanged = true
              individualSegments.baseScore = 0;
            })
          })
          return {...textPartData};
        }
        ))
        disableReleaseButtonHandler(editedTextDetails);
        setSelectedSegment((prevState) => ({
          ...prevState,
          textId: null,
          partId: null,
          objShort: null,
          partName: null,
          segmentId: null,
          translationSuggestionLength: null,
          confirmButtonClicked: true,
          sourceSegmentText: null,
        }));
        sitsContext.updateEditorBottomLeftHeader('concordance');
        setIsConfirmButtonIsClicked(true);
      }, 1500);
    } else if (emptyTextCount !== textSegmentLength) {
      const confirmedTranslationMemoryTextObj = translationMemoryPayload.filter((tmObj) => {
        return tmObj.TextID === textIdconfirm;
    })

      // const confirmedTranslationMemoryTextObj = translationMemoryPayload[indexOfConfirmedTextTm];
    if (segmentsChanges.length > 0) {
      const updatedTranslationUnitSegments = confirmedTranslationMemoryTextObj.map((translationUnitData) => {
       translationUnitData.TranslationUnitRequestList.map((segmentData) => {
        segmentsChanges.map(async (indSegment) => {
          if (indSegment.textId === translationUnitData.TextID
              && indSegment.partId === translationUnitData.Part_ID
              && indSegment.sourceText === segmentData.TranslationUnit.Source['Text']) {
              if (indSegment.targetText !== segmentData.TranslationUnit.Target['Text']) {
                segmentData.Settings.ExistingTUsUpdateMode = "AddNew"
                if (indSegment.targetText !== null && indSegment.targetText !== "") {
                  segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.trimStart().trimEnd();
                  segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.replace(/\s\s+/g, ' ');
                } else {
                  segmentData.TranslationUnit.Target['Text'] = indSegment.targetText;
                }
              } else {
                if (semiAutomaticCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())) {
                  newMatchSuggestion = checkIfSameTextSemiAutoSubscribeCountriesHandler(
                    indSegment.targetText,
                    translationUnitData.TextID,
                    translationUnitData.Part_ID,
                    segmentData.TranslationUnit.Source['Text']
                  );
                } else {
                  editedTextDetails.forEach((textDetails) => {
                    textDetails.textObjectParts.forEach((textPartsDetails) => {
                       if ((textDetails.textId === translationUnitData.TextID)
                        && (textPartsDetails.textPart === translationUnitData.Part_ID)) {
                         textPartsDetails.textSegments.forEach((segmentsDetails) => {
                           if (segmentsDetails.sourceSegment
                            === segmentData.TranslationUnit.Source['Text']) {
                            if (segmentsDetails.baseScore > 0 && segmentsDetails.baseScore < 100) {
                              newMatchSuggestion = true
                            } else {
                              newMatchSuggestion = false
                            }
                           }
                         })
                       }
                    })
                  })
                }
               
                if (newMatchSuggestion) {
                  segmentData.Settings.ExistingTUsUpdateMode = "AddNew"
                  if (indSegment.targetText !== null && indSegment.targetText !== "") {
                    segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.trimStart().trimEnd();
                    segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.replace(/\s\s+/g, ' ');
                  } else {
                    segmentData.TranslationUnit.Target['Text'] = indSegment.targetText;
                  }
                } else {
                  segmentData.Settings.ExistingTUsUpdateMode = "LeaveUnchanged"
                }
              }
          }
        })
        return {...segmentData}
       }) 
        return {...translationUnitData}
      })
      tmPayload = [...updatedTranslationUnitSegments];
    } else if (segmentsChanges.length === 0) {
      const updatedTranslationUnitSegments = confirmedTranslationMemoryTextObj.map((translationUnitData) => {
        translationUnitData.TranslationUnitRequestList.map((segmentData) => {
           if (selectedTextDetails.textId === translationUnitData.TextID
               && selectedTextDetails.partId === translationUnitData.Part_ID) {
                  if (semiAutomaticCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())) {
                   newMatchSuggestion = checkIfSameTextSemiAutoSubscribeCountriesHandler(
                    segmentData.TranslationUnit.Target['Text'],
                    translationUnitData.TextID,
                    translationUnitData.Part_ID,
                    segmentData.TranslationUnit.Source['Text']
                  );
                  } else {
                    editedTextDetails.forEach((textDetails) => {
                      textDetails.textObjectParts.forEach((textPartsDetails) => {
                         if ((textDetails.textId === translationUnitData.TextID)
                          && (textPartsDetails.textPart === translationUnitData.Part_ID)) {
                           textPartsDetails.textSegments.forEach((segmentsDetails) => {
                             if (segmentsDetails.sourceSegment
                              === segmentData.TranslationUnit.Source['Text']) {
                              if (segmentsDetails.baseScore > 0 && segmentsDetails.baseScore < 100) {
                                newMatchSuggestion = true
                              } else {
                                newMatchSuggestion = false
                              }
                             }
                           })
                         }
                      })
                    })
                  }
                 if (newMatchSuggestion) {
                  segmentData.Settings.ExistingTUsUpdateMode = "AddNew"
                  if (segmentData.TranslationUnit.Target['Text'] !== null
                    && segmentData.TranslationUnit.Target['Text'] !== "") {
                    segmentData.TranslationUnit.Target['Text'] = segmentData.TranslationUnit.Target['Text'].trimStart().trimEnd();
                    segmentData.TranslationUnit.Target['Text'] = segmentData.TranslationUnit.Target['Text'].replace(/\s\s+/g, ' ');
                  } else {
                    segmentData.TranslationUnit.Target['Text'] = segmentData.TranslationUnit.Target['Text'];
                  }
                 } else {
                  segmentData.Settings.ExistingTUsUpdateMode = "LeaveUnchanged"
                }
           }
         return {...segmentData}
        }) 
         return {...translationUnitData}
      })
      tmPayload = [...updatedTranslationUnitSegments];
    }
      let isMultipleSeg = false;
      let target_text = '';
      let updatedEditDetails = detialsofConfirmedText.map((textDetail) => {
        if (sitsContext.autoSaveEnabled === true
          && textDetail.status.toLowerCase()
          === sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
          textDetail.previousStatus = sitsContext.actionToStatusMapping.autosave;
          textDetail.status = sitsContext.actionToStatusMapping.confirm;
        } else if (textDetail.status !== "In progress") {
          textDetail.status = sitsContext.actionToStatusMapping.confirm;
        }
        textDetail.textObjectParts.map((textPartInfo) => {
          textPartInfo.textSegments.map((segmentsInfo) => {
            segmentsInfo.textChanged = false
            if (segmentsInfo.translatedSegment !== null && segmentsInfo.translatedSegment !== "") {
              segmentsInfo.translatedSegment = segmentsInfo.translatedSegment.trimStart().trimEnd();
              segmentsInfo.translatedSegment = segmentsInfo.translatedSegment.replace(/\s\s+/g, ' ');
            } else {
              segmentsInfo.translatedSegment = segmentsInfo.translatedSegment;
            }
            return {...segmentsInfo}
          })
          multiSegmentDetails.forEach((multiOuterSegment) => {
            if ((multiOuterSegment.text_id === textDetail.textId)
              && (multiOuterSegment.part_id === textPartInfo.textPart)
            && (multiOuterSegment.part_name === textPartInfo.textPartName)){
              isMultipleSeg = true; 
              multiOuterSegment.segments_details.forEach((individualSegments) => {
                target_text = target_text + individualSegments.translated_text
                + individualSegments.delimiter;
              })
            }
          });
          if (isMultipleSeg) {
            isMultipleSeg = false;
            return {...textPartInfo, value: target_text}
          } else if (!isMultipleSeg) {
            return {...textPartInfo}
          }
        })
        return {...textDetail}
      })
      setEditValue(editValue.map(textPartData => {
        if(textIdconfirm === textPartData.textid){
          textPartData.confirmationInProgress = true;
          }
        return {...textPartData};
      }
      ))
      disableReleaseButtonHandler(editedTextDetails);
      saveTranslation(updatedEditDetails, translationSavedCallbackHandler, textIdconfirm);
    }
}

  const translationSavedCallbackHandler = (editedResponse, confirmedTextId) => {
    if (editedResponse.status === 200) {
      if (selectedSegment.objShort.toLowerCase() === "value") {
        if (editedResponse.data[0].similarValueTranslationExists === false) {
          if (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true) {
            duplicateTranslationTextRemoveHandler(confirmedTextId, "whole", true);
          }
          if (!(confirmedTexts.includes(confirmedTextId))) {
            setConfirmedTexts(prevState => ([
              ...prevState,
              confirmedTextId
            ]))
          }
          updatedOnDate = editedResponse.data[0].updatedon.split('T')[0];
          updateSelectedTextStatusHandler(
            confirmedTextId,
            sitsContext.actionToStatusMapping.confirm.toLowerCase()
          );
        } else {
          setEditValue(editValue.map(textPartData => {
            if(confirmedTextId === textPartData.textid){
              textPartData.confirmationInProgress = false;
              }
            return {...textPartData};
          }
          ))
          duplicateTranslationHandler(
            confirmedTextId,
            editedResponse.data[0].similarSourceForTheTranslation,
          );
          return;
        }
      } else if (selectedSegment.objShort.toLowerCase() === "lngtxt"
      || selectedSegment.objShort.toLowerCase() === "mdmtxt") {
        if (editedResponse.data[0].similarLongOrMediumNameTranslationExists === true) {
          setEditValue(editValue.map(textPartData => {
            if(confirmedTextId === textPartData.textid){
              textPartData.confirmationInProgress = false;
              }
            return {...textPartData};
          }
          ))
          duplicateTranslationHandler(
            confirmedTextId,
            editedResponse.data[0].similarSourceForTheTranslation,
          );
          return;
        } else {
          if (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true) {
            duplicateTranslationTextRemoveHandler(confirmedTextId, "whole", true);
          }
          if (!(confirmedTexts.includes(confirmedTextId))) {
            setConfirmedTexts(prevState => ([
              ...prevState,
              confirmedTextId
            ]))
          }
          updatedOnDate = editedResponse.data[0].updatedon.split('T')[0];
          updateSelectedTextStatusHandler(
            confirmedTextId,
            sitsContext.actionToStatusMapping.confirm.toLowerCase()
          );
        }
      } else {
        if (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true) {
          if (selectedSegment.objShort.toLowerCase() === "lngtxt"
        || selectedSegment.objShort.toLowerCase() === "mdmtxt"
        || selectedSegment.objShort.toLowerCase() === "value") {
          duplicateTranslationTextRemoveHandler(confirmedTextId, "whole", true);
        }
      }
        if (!(confirmedTexts.includes(confirmedTextId))) {
          setConfirmedTexts(prevState => ([
            ...prevState,
            confirmedTextId
          ]))
        }
          updatedOnDate = editedResponse.data[0].updatedon.split('T')[0];
          updateSelectedTextStatusHandler(
            confirmedTextId,
            sitsContext.actionToStatusMapping.confirm.toLowerCase()
          );
      }
    } else if (editedResponse.response.status !== 200 && editedResponse.response.status >= 400) {
      setErrorScenarioNumber(9);
      setErrorMessage(errorHeader.errorOnConfirmingTextHeader);
      setEnableErrorHandler(true);
      correlationIdEditorPage = editedResponse.response.data.CorrelationID;
    }
    saveTranslationInTm(tmPayload, savedTmCallbackHandler, confirmedTextId);
  }

  const savedTmCallbackHandler = (response, confirmedTextId) => {
    const index= navigateEditPagePayload.findIndex(x => x.textid === confirmedTextId);
    let routingData = [{
      textid: navigateEditPagePayload[index].textid,
      source_locale_code: navigateEditPagePayload[index].source_locale_code,
      target_locale_code: navigateEditPagePayload[index].target_locale_code,
      object_type_short: navigateEditPagePayload[index].object_type_short,
      isParentEnabled: true, 
      }]
     if (response.status === 200) {
      navigateToEditPage(routingData, updateMatchesSuggestionsHandler, confirmedTextId);    
     } else if (response.response.status !== 200) {
      setSelectedSegment((prevState) => ({
        ...prevState,
        textId: null,
        partId: null,
        partName: null,
        objShort: null,
        segmentId: null,
        translationSuggestionLength: null,
        confirmButtonClicked: true,
        sourceSegmentText: null,
      }));
      sitsContext.updateEditorBottomLeftHeader('concordance');
      setIsConfirmButtonIsClicked(true);
      setChangesSaved(true);
      setErrorScenarioNumber(8);
      setErrorMessage(errorHeader.errorOnTMavailabilityHeader);
      setEnableErrorHandler(true);
     }
  };

  const updateMatchesSuggestionsHandler = (response) => {
    setTimeout(() => {
        setSelectedSegment((prevState) => ({
          ...prevState,
          confirmButtonClicked: true,
        }))
        sitsContext.updateEditorBottomLeftHeader('concordance');
        setIsConfirmButtonIsClicked(true);
        setChangesSaved(true);
        getEditorData(response, 'ConfirmClicked');
      }, 1500);
  };
  const saveOnKeyHandler = (saveTmOnKeyUpHandler) => {
    // editedtextdetails is the payload of all texts
    // detialsofconfirmedtext contains the selected text payload
    let checkIfEmpty = false;
    let detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
      if (payloadData.textId === selectedSegment.textId) {
        return {...payloadData}
      }
    });
    detialsofConfirmedText.map((editedText) => {
      if (editedText.textId === selectedSegment.textId) {
        if (sitsContext.autoSaveEnabled === true) {
          if (editedText.status.toLowerCase()
            !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
            editedText.status = sitsContext.actionToStatusMapping.autosave;
          }
        }
      }
      editedText.textSegments.forEach((segmentsInfo) =>{
        if (segmentsInfo.segmentId=== selectedSegment.segmentId
          && segmentsInfo.translatedSegment === ""){
            checkIfEmpty = true ;
          }
        })
      return {...editedText}
    })
    if (checkIfEmpty === false) {
      saveTmOnKeyUpHandler();
    } else {
      highlightNextElementHandler();
    }
  };
 
  const saveTmOnKeyUpHandler = () => {
    const confirmedTranslationMemoryTextObj = translationMemoryPayload.filter((tmObj) => {
      return tmObj.TextID === selectedSegment.textId;
    })
    if (segmentsChanges.length > 0) {
      const updatedTranslationUnitSegments = confirmedTranslationMemoryTextObj.map((translationUnitData) => {
        translationUnitData.TranslationUnitRequestList.map((segmentData) => {
          segmentsChanges.map((indSegment) => {
           if (indSegment.textId === translationUnitData.TextID
            && indSegment.partId === translationUnitData.Part_ID
            && indSegment.sourceText === segmentData.TranslationUnit.Source['Text']) {
            if (indSegment.targetText !== segmentData.TranslationUnit.Target['Text']) {
              segmentData.Settings.ExistingTUsUpdateMode = "AddNew"
              if (indSegment.targetText !== null && indSegment.targetText !== "") {
                segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.trimStart().trimEnd();
                segmentData.TranslationUnit.Target['Text'] = indSegment.targetText.replace(/\s\s+/g, ' ');
              } else {
                segmentData.TranslationUnit.Target['Text'] = indSegment.targetText;
              }
            } else {
              segmentData.Settings.ExistingTUsUpdateMode = "LeaveUnchanged"
            }
        }
      })
      return {...segmentData}
     })
      return {...translationUnitData}
    })
    tmPayload = [...updatedTranslationUnitSegments];
  } else if (segmentsChanges.length === 0) {
    tmPayload = [...confirmedTranslationMemoryTextObj];
  }
  saveTranslationInTm(tmPayload, saveTranslationOnKeyHandler);
};

const saveTranslationOnKeyHandler = () => {
  let detialsofConfirmedText = editedTextDetails.filter((payloadData) => {
    if (payloadData.textId === selectedSegment.textId) {
      return {...payloadData}
    }
  });
  detialsofConfirmedText.map((editedText) => {
    if (editedText.textId === selectedSegment.textId) {
      if (sitsContext.autoSaveEnabled === true) {
        if (editedText.status.toLowerCase()
          !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
          editedText.status = sitsContext.actionToStatusMapping.autosave;
        }
      }
     
      editedText.textSegments.map((segmentsDetails) => {
        if (segmentsDetails.segmentId === selectedSegment.segmentId) {
          segmentsDetails.baseScore = 100;
          segmentsDetails.match = 100;
        }
        return {...segmentsDetails}
      })
    }
    return {...editedText}
  })
  saveTranslation(detialsofConfirmedText, savedOnKeyUpCallbackHandler);
};
 
const savedOnKeyUpCallbackHandler = (response) => {
  const index= navigateEditPagePayload.findIndex(x => x.textid === selectedSegment.textId);
  let routingData = [{
      textid: navigateEditPagePayload[index].textid,
      source_locale_code: navigateEditPagePayload[index].source_locale_code,
      target_locale_code: navigateEditPagePayload[index].target_locale_code,
      object_type_short: navigateEditPagePayload[index].object_type_short,
      isParentEnabled: true,
  }]
    if (response.status === 200) {
      navigateToEditPage(routingData, getUpdatedDataOnKeyUpHandler);    
    }
};
 
const getUpdatedDataOnKeyUpHandler = (response) => {
  getEditorData(response, 'saveOnKeyUp');
  let foucsElement = document.activeElement;
  let textAreaTag = document.getElementsByTagName('textarea');
  let focusElementIndex;
  for (let i = 0; i < textAreaTag.length; i++) {
    if (textAreaTag[i].id === foucsElement.id) {
      focusElementIndex = i+1;
    }
  }
  if (focusElementIndex < textAreaTag.length) {
    const nextFocusElement = document.getElementById(textAreaTag[focusElementIndex].id);
    nextFocusElement.focus();
  } else if (focusElementIndex >= textAreaTag.length) {
    const confirmButtonElement = document.getElementById('confirmButtonEditor');
    setTimeout(() => {
      confirmButtonElement.focus();
      confirmButtonElement.classList.add('focus-visible')
    }, 500);
  }
};
 
  const handleKeyDown = (event) => {
    let foucsElement = document.activeElement;
    if (event.ctrlKey && event.key === 'Enter') {
      if (foucsElement.tagName.toLowerCase() === 'textarea') {
        saveOnKeyHandler(saveTmOnKeyUpHandler);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  })
  // Function for handling the keyboard shortcuts in the editor page 
  const handleKeyboardShortcuts = (e) => {
    // Function for intiating the concordance search
    // F3 -> shortcut key to intiate concordance tab in editor page
    if(e.key === 'F3'){
    const selectedText = window.getSelection().toString().trim();
    if (selectedText.length !== 0) {
      // intiating the concordance search with selected text 
      sitsContext.updateConcordanceRetrieveData("noMatchesIndicator", true);
      sitsContext.updateConcordanceRetrieveData("tabChange", true);
      sitsContext.updateConcordanceRetrieveData("searchedText", selectedText);
      sitsContext.updateEditorBottomLeftHeader("concordance");
      sitsContext.updateshortcutKeyForConcordance(true);
    } else {
      // intiating the concordance search without any text
      sitsContext.updateEditorBottomLeftHeader("concordance");
      sitsContext.updateshortcutKeyForConcordance(true);
    }
    e.preventDefault();
  };
}

  const setDynamicHeightHandler = (event, elementId) => {
    let eleId = event.target.getAttribute('id');
    let el = document.getElementById(eleId);
    el.style.height = "5px";
    el.style.height = (el.scrollHeight) + "px";
    el.style.overflow = 'hidden';    
  }

const onClickTableRowHandler = async (event, translatedText, textRowData, textPartData, segmentId, segmentsData, idOfElement) => {
  if(idOfElement !== 0){
    setSaveIdOfTextArea((prevState) => ({
      ...prevState,
      id: idOfElement,
      translation: translatedText
    }));
   }
    if ((selectedSegment.textId !== textRowData.textid)) {
      setShowHistoryTab(true);
      setExpandButton(false);
      setUsedInExpandButton(false);
      let bulkTextPartEmptyErrorDetails = {};
      let bulkTextParagraphMismatchErrorDetails = {};
      let bulkTextDuplicateTranslationErrorDetails = {};

      // Text part empty error handling
      sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorBulkTextDetails.forEach((textErrorDetails) => {
        if (textErrorDetails.textId === textRowData.textid) {
          bulkTextPartEmptyErrorDetails = {
            ...textErrorDetails
          }
        }
      })
      bulkTextPartEmptyErrorDetails = {
        ...bulkTextPartEmptyErrorDetails,
        openTextPartEmptyErrorPopup: false,
      }
      sitsContext.updateTextpartEmptyErrorEditor(bulkTextPartEmptyErrorDetails);

      // Paragraph mismatch error handling 
      sitsContext.paragraphMismatchErrorEditor.paragraghMismatchErrorBulkTextDetails.forEach(
        (paragraphMismatchDetails) => {
          if (paragraphMismatchDetails.textId === textRowData.textid) {
            bulkTextParagraphMismatchErrorDetails = {
              ...paragraphMismatchDetails,
              openParagraphMismatchErrorPopup: false
            }
          }
      })
      sitsContext.updateParagraphMismatchErrorEditor(bulkTextParagraphMismatchErrorDetails)

      // Duplicate Translation
      let notDuplicateTranslationText = false;
      sitsContext.duplicateTranslation.duplicateTranslationErrorBulkTextDetails.forEach(
        (duplicateTranslationDetails) => {
          if (duplicateTranslationDetails.textId === textRowData.textid) {
            bulkTextDuplicateTranslationErrorDetails = {
              ...duplicateTranslationDetails,
              openDuplicateTranslationPopup: false
            }
            notDuplicateTranslationText = true;
          }
      });
      if (notDuplicateTranslationText === false) {
        bulkTextDuplicateTranslationErrorDetails = {
          openDuplicateTranslationPopup: false,
          duplicateTranslationErrorFlag: false,
          sourceTextOfDuplicateTranslation: '',
          textId: null,
        }
      }
      sitsContext.updateDuplicateTranslation(bulkTextDuplicateTranslationErrorDetails)
    }
    let validSuggestionsLength = 0;
    let selectedMetaData;
    if ((selectedSegment.textId !== textRowData.textid) || (selectedSegment.partId !== textPartData.partId)
    || (selectedSegment.partName !== textPartData.partName)
    || (selectedSegment.segmentId !== segmentId)) {
      setSegmentToGetMatches(segmentsData.segment);
      setLoadMatchesSuggestions(true);
      sitsContext.updateEditorBottomLeftHeader('matches');
      const clearedObj = {
        searchedText: null,
        searchedResults: [],
        scrollPosition: 0,
        noResults: false,
        noMatchesIndicator: false,
      };
      sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
if (selectedSegment.textId === textRowData.textid){
  setSelectedSegment({
    textId: textRowData.textid,
    partId: textPartData.partId,
    partName: textPartData.partName,
    objShort: textRowData.objNameShort,
    segmentId: segmentId,
    translationSuggestionLength: validSuggestionsLength,
    confirmButtonClicked: false,
    tableRowClicked: true,
    sourceSegmentText: segmentsData.segment,
    textRowChanged: false,
    initialRendering: false,
    statusOfTheText: textPartData.status,
  })
 } else if (selectedSegment.textId !== textRowData.textid) {
        setSelectedSegment({
          textId: textRowData.textid,
          partId: textPartData.partId,
          partName: textPartData.partName,
          objShort: textRowData.objNameShort,
          segmentId: segmentId,
          translationSuggestionLength: validSuggestionsLength,
          confirmButtonClicked: false,
          tableRowClicked: true,
          sourceSegmentText: segmentsData.segment,
          textRowChanged: true,
          initialRendering: false,
          statusOfTheText: textPartData.status,
        })
        const connectedProdInfo = {
          textId: null,
          itemsConnected: [],
        }
        sitsContext.updateConnectedProdResData(connectedProdInfo);
        setCallConnectedProducts(true);
  }
    }
    setSelectedTextDetails((prevState) => ({
      ...prevState,
      partId: textPartData.partId,
      textId: textRowData.textid,
      partName: textPartData.partName,
      segmentId: segmentId,
    }));
    let targetValue;
    setIsTableRowChanged(false);
    
    if ((editedTextDetails.length !== 0) && (textPartData.segments.length === 1)){
    editedTextDetails.forEach(textData => {
      textData.textObjectParts.forEach((textPartInfo) => {
        if ((textData.textId === textRowData.textid ) && (textPartInfo.textPartName === textPartData.partName )
          && (textPartInfo.textPart === textPartData.partId)) { 
            textPartInfo.textSegments.forEach((textSegmentsData) => {
            if (textSegmentsData.segmentId === 0) {
              if (textSegmentsData.translatedSegment !== '' && textSegmentsData.translatedSegment !== null) {
                targetValue = textSegmentsData.translatedSegment.length; 
              } else {
                targetValue = 0;
              }
            }
          })
        }
      })
    })
  } else if ((textPartData.segments.length > 1)) {
     multiSegmentDetails.forEach((segmentDetails) => {
       if ((textRowData.textid === segmentDetails.text_id)
       && (textPartData.partName === segmentDetails.part_name)
      && (textPartData.partId === segmentDetails.part_id)) {
            targetValue = segmentDetails.totalCharactersCount;
       }
     });
  } else if ((editedTextDetails.length === 0) && (textPartData.segments.length === 1)) {
      targetValue = translatedText.length;
  }
  if (targetValue === undefined) {
    targetValue = 0;
  }

  if (isConfirmButtonIsClicked === true) {
    setIsConfirmButtonIsClicked(false);
    setSegmentToGetMatches(segmentsData.segment);
    setLoadMatchesSuggestions(true);
    if (confirmButtonClickedOnEmptyText === true) {
      setConfirmButtonClickedOnEmptyText(false);
      selectedMetaData = {
        objectType: textRowData.objectType,
        category: textRowData.category,
        description: (textRowData.description !== null && textRowData.description !== undefined) ? textRowData.description : null,
        name: textRowData.name,
        type: textPartData.partName,
        mainProductType: (textRowData.mainProductType !== null && textRowData.mainProductType !== undefined) ? textRowData.mainProductType : null,             
        updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn,
        charCount: spaceAfterhundreds(targetValue) + " / " + spaceAfterhundreds(textPartData.maxCharCount),
        usedin: (textRowData.usedIn !== null && textRowData.usedIn !== undefined) ? textRowData.usedIn : null
      }
      setMetaDataValue(selectedMetaData);
    } else {
    setMetaDataValue(prevState => {
      return {
        ...prevState,
        charCount: spaceAfterhundreds(targetValue) + " / " + spaceAfterhundreds(textPartData.maxCharCount),
        updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn
      }
    })
   }
  } else {
    selectedMetaData = {
      objectType: textRowData.objectType,
      category: textRowData.category,
      description: (textRowData.description !== null && textRowData.description !== undefined) ? textRowData.description : null,
      name: textRowData.name,
      type: textPartData.partName,
      mainProductType: (textRowData.mainProductType !== null && textRowData.mainProductType !== undefined) ? textRowData.mainProductType : null,
      updatedBy: textPartData.updatedBy + " " + textPartData.updatedOn,
      charCount: spaceAfterhundreds(targetValue)+ " / " + spaceAfterhundreds(textPartData.maxCharCount),
      usedin: (textRowData.usedIn !== null && textRowData.usedIn !== undefined) ? textRowData.usedIn : null    
    }
    setMetaDataValue(selectedMetaData);
  }
  checkCharacterOverflowHandler(textRowData, textPartData);
};

  const checkCharacterOverflowHandler = (textRowData, textPartData) => {
    let characterExceedErrorLocal = false;
    let translationLength;
    let selectedPartHasCharOverflow = false;
    if (editedTextDetails.length !== 0) {
      editedTextDetails.forEach(textData => {
        if ((textData.textId === textRowData.textid )) {
        textData.textObjectParts.forEach(textPartInfo => {
            const partIdindex = textRowData.textpart.findIndex(item => item.partId === textPartInfo.textPart);
            if (textPartInfo.textSegments.length === 1) {
              textPartInfo.textSegments.forEach((textSegmentsData) => {
                if (textSegmentsData.segmentId === 0) {
                  if (textSegmentsData.translatedSegment !== ''
                    && textSegmentsData.translatedSegment !== null) {
                    translationLength = textSegmentsData.translatedSegment.length; 
                  } else {
                    translationLength = 0;
                  }
                  if (translationLength
                    > textRowData.textpart[partIdindex].maxCharCount) {
                    if (textPartInfo.textPart === textPartData.partId) {
                      selectedPartHasCharOverflow = true;
                      characterExceedErrorLocal = true;
                      setCharacterExceededData(prevState => {
                        return {
                          ...prevState,
                          textId: textData.textId,
                          partId: textPartInfo.textPart,
                          partName: textPartInfo.textPartName,
                          segmentId: 0,
                          maximumCharacterCount: textRowData.textpart[partIdindex].maxCharCount,
                          selectedPartHasError: true,
                        }
                      })
                    } else if (selectedPartHasCharOverflow === false) {
                      if (characterExceedErrorLocal === false) {
                        characterExceedErrorLocal = true;
                        setCharacterExceededData(prevState => {
                          return {
                            ...prevState,
                            textId: textData.textId,
                            partId: textPartInfo.textPart,
                            partName: textPartInfo.textPartName,
                            segmentId: 0,
                            maximumCharacterCount: textRowData.textpart[partIdindex].maxCharCount,
                            selectedPartHasError: false,
                          }
                        })
                      }
                    }
                  }
                }
              })
            } else if (textPartInfo.textSegments.length > 1) {
              multiSegmentDetails.forEach((segmentDetails) => {
                if ((textRowData.textid === segmentDetails.text_id)
                && (textRowData.textpart[partIdindex].partName === segmentDetails.part_name)
               && (textRowData.textpart[partIdindex].partId === segmentDetails.part_id)) {
                translationLength = segmentDetails.totalCharactersCount;
                     if (translationLength > textRowData.textpart[partIdindex].maxCharCount) {
                      if (textPartInfo.textPart === textPartData.partId) {
                        selectedPartHasCharOverflow = true;
                        characterExceedErrorLocal = true;
                        setCharacterExceededData(prevState => {
                          return {
                            ...prevState,
                            textId: textData.textId,
                            partId: textPartInfo.textPart,
                            partName: textPartInfo.textPartName,
                            segmentId: 0,
                            maximumCharacterCount: textRowData.textpart[partIdindex].maxCharCount,
                            selectedPartHasError: true,
                          }
                        })
                      } else if (selectedPartHasCharOverflow === false) {
                        if (characterExceedErrorLocal === false) {
                          characterExceedErrorLocal = true;
                          setCharacterExceededData(prevState => {
                            return {
                              ...prevState,
                              textId: textData.textId,
                              partId: textPartInfo.textPart,
                              partName: textPartInfo.textPartName,
                              segmentId: 0,
                              maximumCharacterCount: textRowData.textpart[partIdindex].maxCharCount,
                              selectedPartHasError: false,
                            }
                          })
                        }
                      }
                     }
                }
              });
            }
        })
       }
      })
    }
    
    if (characterExceedErrorLocal === true) {
      setCharacterExceededError(true);
    } else if (characterExceedErrorLocal === false) {
      setCharacterExceededData(prevState => {
        return {
          ...prevState,
          selectedPartHasError: false,
        }
      })
      if (characterExceededError) {
        setCharacterExceededError(false);
      }
    }
  }

  const onChangeInputHandler = (
    event,
    segmentSourceText,
    translatedText,
    textPartValue,
    textId,
    objType,
    Objshort,
    segmentId,
    categoryText,
    sourceSystemText,
    id,
    editDetails,
  ) => {
    if( trimSpace === true ) {
       document.getElementById(id).value = document.getElementById(id).value.replace(/^\s*|\s*$/g,'');
       setTrimSpace(false)
    }
    let updatedEditedTextDetails = editedTextDetails.map((editedText) => {
      editedText.textObjectParts.map((textPartInfo) => {
        if (editedText.textId === textId) {
          if (sitsContext.autoSaveEnabled === true) {
            updateSelectedTextStatusHandler(textId, sitsContext.actionToStatusMapping.autosave.toLowerCase());
            if (editedText.status.toLowerCase()
              !== sitsContext.actionToStatusMapping.autosave.toLowerCase()) {
              editedText.status = sitsContext.actionToStatusMapping.autosave;
            }
          }
          if (textPartValue.partName === textPartInfo.textPartName) {
            textPartInfo.textSegments.map((textSegmentsData) => {
              if (textSegmentsData.segmentId === segmentId) {
                textSegmentsData.translatedSegment = event.target.value;
                textSegmentsData.textChanged = true;
              }
              return {...textSegmentsData}
            })
          }
        }
        return {...textPartInfo}
      })
      return {...editedText}
    })
    setEditedTextDetails(updatedEditedTextDetails);
    if ((sitsContext.autoSaveEnabled=== true) && 
    (confirmedTexts.length !== 0) && confirmedTexts.includes(textId)) {
      setConfirmedTexts(confirmedTexts.filter(confirmedTextId => confirmedTextId !== textId));
    }
    setTypedText(event.target.value);
    setEditValue(editValue.map(textPartData => {
      textPartData.textpart.map(segmentsData => {
        segmentsData.segments.map(individualSegments => {
          if ((textPartData.textid === textId) && (segmentsData.partId === textPartValue.partId)
            && (segmentsData.partName === textPartValue.partName)
            && (individualSegments.segementID === segmentId)) {
              individualSegments.textChanged = true;
              individualSegments.translated_text = event.target.value;
            }
        })
      })
      return {...textPartData};
    }
    ))
    let target_text_length = 0;
    if (textPartValue.segments.length > 1){
    multiSegmentDetails.forEach((multiOuterSegment) => {
      if ((multiOuterSegment.text_id === textId) && (multiOuterSegment.part_id === textPartValue.partId)
      && (multiOuterSegment.part_name === textPartValue.partName)) {
        multiOuterSegment.segments_details.forEach((individualSegments) => {
              if ((multiOuterSegment.segments_details.length - 1) !== segmentId) {
                if (individualSegments.segementID === segmentId) {
                  individualSegments.translated_text = event.target.value;
                  if (event.target.value.length !== 0) {
                    individualSegments.targetTextLength = event.target.value.length + 1;
                  } else {
                    individualSegments.targetTextLength = 0;
                  }
                }
              } else if ((multiOuterSegment.segments_details.length - 1) === segmentId) {
                if (individualSegments.segementID === segmentId) {
                  individualSegments.translated_text = event.target.value;
                  individualSegments.targetTextLength = event.target.value.length;
                }
              }
              if (individualSegments.targetTextLength !== undefined) {
                target_text_length += individualSegments.targetTextLength; 
              }
        })
        multiOuterSegment.totalCharactersCount = target_text_length;
      }
    });
  } else if (textPartValue.segments.length === 1) {
    target_text_length = event.target.value.length;
  }

  setMetaDataValue(prevState => {
      return {...prevState, charCount: spaceAfterhundreds(target_text_length) + " / " + spaceAfterhundreds(textPartValue.maxCharCount)}
    })
    let updatedSegmentInfo = {
      textId: textId,
      partId: textPartValue.partId,
      partName: textPartValue.partName,
      segmentId: segmentId,
      sourceText: segmentSourceText,
      targetText: event.target.value,
    }
   if (segmentsChanges.length === 0) {
    setSegmentsChanges(segmentsChanges => [...segmentsChanges, updatedSegmentInfo]);
   } else if (segmentsChanges.length !== 0) {
    let segmentsChangesInfo = segmentsChanges.map((indSegmentChange) => {
      if (indSegmentChange.textId === textId
        && indSegmentChange.partId === textPartValue.partId
        && indSegmentChange.sourceText === segmentSourceText) {
        segmentChangesFlag = true;
        return {...indSegmentChange, targetText: event.target.value}
      }
      return indSegmentChange;
    })
    if (segmentChangesFlag === true) {
      setSegmentsChanges(segmentsChangesInfo);
      segmentChangesFlag=false;
    } else if (segmentChangesFlag === false) {
      setSegmentsChanges(segmentsChanges => [...segmentsChanges, updatedSegmentInfo]);
    }
   }

   // Error check if we have paragraph mismatch error / mandatory field empty error / segments missing validation
   if (sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
    || sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
    || sitsContext.segmentsMissingValidation.segmentsMissingValidationFlag === true) {
      const confirmButtonFlag = false;
      errorCheckOnConfirmHandler(confirmButtonFlag, updatedEditedTextDetails);
    }

    if (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true) {
      if (selectedSegment.objShort.toLowerCase() === "lngtxt"
    || selectedSegment.objShort.toLowerCase() === "mdmtxt"
    || selectedSegment.objShort.toLowerCase() === "value") {
      duplicateTranslationTextRemoveHandler(textId, textPartValue.partName, undefined);
    }
    }

    if (sitsContext.isUseSuggestionClicked === false) {
      // Character overflow error check
      checkCharacterOverflowHandler(editDetails, textPartValue);
    }
  };

  const disableErrorModal = () => {
    setEnableErrorHandler(false);
    if (errorScenarioNumber === 10) {
      setAutoSaveFail(true);
      props.updateAutoSaveErrorFlag(true);
    }
  }

  const compareText = (textId, partId, partName, segmentId) => {
    if ((characterExceededData.textId === textId)
      && (characterExceededData.partId === partId)
      && (characterExceededData.partName === partName)
    ) {
      return true;
    }
    return false;
  };

  const onMouseDownHandler = (e) => {
    const viewportHeight = window.innerHeight;
    const editorTopSectionHeight = viewportHeight - editorBottomHeight;
    const thresholdValue = (10 * (editorTopSectionHeight)) / 100;
    const topSectionHeight = editorTopSectionHeight - thresholdValue;
    if ((topSectionHeight) < (e.clientY)) {
      sitsContext.updateEditorResizeMousePos(e.clientY);
      sitsContext.updateEditorResizeObj('resizeFlag', true);
    } else if ((topSectionHeight) > (e.clientY)) {
      sitsContext.updateEditorResizeObj('resizeFlag', false);
    }
  };

  const onMouseUpHandler = () => {
    sitsContext.updateEditorResizeObj('resizeFlag', false);
  };

   // eslint-disable-next-line no-unused-vars
   const onMouseOverHandler = (e) => {
    let topSectionHeight;
    const viewportHeight = window.innerHeight;
    const editorTopSection= document.getElementById('editorContainer');
    const editorTopSectionHeight = viewportHeight - editorBottomHeight;
    if (sitsContext.editorResizeObj.resizeFlag === true) {
      const thresholdValue = (20 * (editorTopSectionHeight)) / 100;
      topSectionHeight = editorTopSectionHeight - thresholdValue;
    } else if (sitsContext.editorResizeObj.resizeFlag === false) {
      const thresholdValue = (10 * (editorTopSectionHeight)) / 100;
      topSectionHeight = editorTopSectionHeight - thresholdValue;
    }
    if ((topSectionHeight) < (e.clientY)) {
      editorTopSection.style.cursor = 'ns-resize';
    } else if ((topSectionHeight) > (e.clientY)) {
      if (sitsContext.editorResizeObj.resizeFlag === true) {
        sitsContext.updateEditorResizeObj('resizeFlag', false);
      }
      editorTopSection.style.cursor = 'default';
    }
    if (sitsContext.editorResizeObj.resizeFlag === true) {
      const dx = (sitsContext.editorResizeMousePos) - e.clientY;
      sitsContext.updateEditorResizeMousePos(e.clientY);
      setEditorBottomResizeValue(dx);
    }
  };


  // Function gets called when scroll happens
  const onScrollEditorTableHandler = (e) => {
    setEditorTableScrollPosition(e.target.scrollTop);
    const pageheight = Math.ceil(e.target.scrollHeight - e.target.scrollTop);
    const scroll = e.target.clientHeight;
   if(textsToBeLoaded.length > 0){ if (
      pageheight === scroll ||
      pageheight === scroll + 1 ||
      pageheight === scroll + 2 ||
      pageheight === scroll - 1 ||
      pageheight === scroll - 2
    ) {
      splittingPayLoadData();
    }
  }
  };

  const updateEditorBottomHeightHandler = (bottomHeight) => {
    setEditorBottomHeight(bottomHeight);
    if (sitsContext.bannerVisible === true) {
      const topEditorTable = document.getElementById('editorContainer');
      const bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - props.heightofbanner - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    } else {
      const topEditorTable = document.getElementById('editorContainer');
      const bottomEditorTable = document.getElementById('editorBottomSection');
      // 52 number denotes the height of the editor header
      const caluclatedHeight = window.innerHeight - (bottomEditorTable.offsetHeight) - 52;
      topEditorTable.style.height = `${caluclatedHeight}px`
      topEditorTable.style.maxHeight = `${caluclatedHeight}px`
    }
  }

  const trimExtraSpacesOnPaste = () => {
   setTrimSpace(true)
  }

  const updateResizeValue = (resizeFlag) => {
    setEditorBottomResizeValue(resizeFlag)
  }

  const statusRowLength = (textpartsLength) => {
    let numOfSegments = 0;
    {
      textpartsLength.map((segementsdata) => {
        segementsdata.segments.map((segmentsCount) => {
          if (segmentsCount.segment.length > 0) {
            numOfSegments = numOfSegments + 1;
          }
        });
      });
    }
    return numOfSegments;
  };

  const showMoreDescription= (value, descriptionText , characterCountofText) => {
    if(value === 'show more'){ setExpandButton(true); extendedDescription = expandingInformationInPill(descriptionText , characterCountofText+11);}
    else if (value === 'show less'){setExpandButton(false); extendedDescription = [];}
  }

  const showMoreUsedin = (value, usedinText , characterCountofText) => {
    if(value === 'show more'){ setUsedInExpandButton(true); extendedUsedIn = expandingInformationInPill(usedinText , characterCountofText+11);}
    else if (value === 'show less'){setUsedInExpandButton(false); extendedUsedIn = [];}
  }

  return (
  /* eslint-disable */
  <div className='editor_container'>
    {displayReleaseModal 
  && <EditorPageReleaseModal 
        confirmedTexts = {confirmedTexts.length}
        totalTexts = {sitsContext.editorSearchPageLocationData.selectedTextData.length}
        toggleEditorReleaseModal = {toggleEditorReleaseModalHandler}
        handleReleaseButton = {releaseButtonhandler}
      />
  }
  <div className='editor_loading_ball' style={{ display: isLoading ? '' : 'none' }}>
        <Loadingcomponent loadingText={loadingText} />
  </div>
  {enableErrorHandler
  && <ErrorHandler 
       disableErrorHandler={disableErrorModal} 
       saveTranslation={true}
       errorMessage={errorMessage}
       errorDisplayed={errorScenarioNumber}
     />
  }
  {
    validationErrorPopupEnabled
    && <ValidationPopup
         validationMessage={validationErrorPopupEnabled}
         validationData={validationErrorMessage}
         historyRestoreFunc= {historyRestoreFuncHandler}
         confirmSaveHandler={saveChangesContinueButtonhandler}
         revertChanges={revertChangesHandler}
       />
  }
  {sitsContext.contextMenuIconClicked && <Contextmenu />}
  {sitsContext.isConnectImageClicked.isImageClicked == true && <ConnectedProductsModal />}
  <div
    onMouseMove={(e) => onMouseOverHandler(e)}
    onMouseDown={(e) => onMouseDownHandler(e)}
    onMouseUp={(e) => onMouseUpHandler(e)}
    onScroll={(e) => onScrollEditorTableHandler(e)}
    className="editortable"
    id="editorContainer"
    style={{ visibility: isLoading ? 'hidden' : 'visible'}}
  >
  <Table fullWidth={true}>
    <TableHeader className="table_header" sticky>
      <tr>
        <th> </th>
        <th className="heading header_languages_padding">{sitsContext.editorSearchPageLocationData.sourceLocale}</th>
        <th className="heading header_languages_padding">{sitsContext.editorSearchPageLocationData.targetLocale}</th>
        <th className="heading header_left_padding">Match</th>
        <th className="heading header_left_padding">Status</th>
      </tr>
    </TableHeader>
    {editValue.map((editDetails, serialNumber) => ( 
    <TableBody className='editorpage_tablebody' key={serialNumber}>
      {editDetails.category === undefined && (
        <tr className="hide-background-color border-on-editable-area table-row-design set-border-top-on-row table-row-design" key={serialNumber}>
          <td className="td-body-set-bordercolor-noHighlight hide-border-onhover width_serial_number remove_bottom_brorder textalign serial_no_editor serial_no_padding"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="50px" /></td>
          <td className="font-design width textalign td_border_topbtm source_lang_col_padding table_cell_border"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="50px" /></td>
          <td className="font-design width textalign td_border_topbtm source_lang_col_padding table_cell_border"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="50px" /></td>
          <td className="match-column-width textalign output_text td_border_topbtm table_cell_border table_content_padding"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="50px" /></td>
          <td className="td_border_topbtm table_cell_border table_content_padding hide-background-color-hover set-verticle-align-status"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="50px" /></td>
        </tr>
      )}
    {(!reRender && editDetails.category !== undefined && editDetails.textpart.map((textPartValue, rowindex) => (
        textPartValue.segments.map((textdata, rowSegmentIndex) => (
         <tr
           key={
            (textPartValue.firstValidRow === true) ? ( rowSegmentIndex === 0 ? 0 : textPartValue.partId + '-'
            + textPartValue.partName + '-' + textdata.segementID)
            : (textPartValue.partId + '-' + textPartValue.partName + '-' + textdata.segementID)
           } 
           id="table-row"
           className={
             (
              !(isConfirmButtonIsClicked) && !(isTableRowChanged) && selectedTextDetails.textId
              === editDetails.textid  && selectedTextDetails.partId === textPartValue.partId
              && selectedTextDetails.partName === textPartValue.partName
              && textdata.segementID === selectedTextDetails.segmentId)
              ? `hide-background-color border-on-editable-area  table-row-design ${characterExceededError
              && compareText(editDetails.textid, textPartValue.partId, textPartValue.partName,
              textdata.segementID) ? 'border-on-char-exceed-error' : ''} `
              : `hide-background-color table-row-design ${characterExceededError
              && compareText(editDetails.textid, textPartValue.partId, textPartValue.partName,
              textdata.segementID)  ? 'border-on-char-exceed-error' : ''} `
            }
            onClick={
              event => onClickTableRowHandler(
                event, textdata.translated_text, editDetails, textPartValue, textdata.segementID,
                textdata, serialNumber
              )
            }
            style={{ display: (textdata.segment === null || textdata.segment === "") ? 'none' : 'null' }}
         >
          <td
            className={
              `width_serial_number remove_bottom_brorder textalign serial_no_editor serial_no_padding
              ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
              ? 'td-body-set-bordercolor' : 'td-body-set-bordercolor-noHighlight hide-border-onhover'}`
            } 
            rowSpan={
             (!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
             ? statusRowLength(editDetails.textpart) + 1 : statusRowLength(editDetails.textpart)
            }
            style={
              { display: (textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'null' : 'none' }
            }
          >
              {serialNumber+1}
          </td>
          <td
          className={
            `${ (sitsContext.editorHorizontalDragData.resizeFlag === true
               || sitsContext.editorResizeObj.resizeFlag === true)
               ? 'editortable_userselection_onresizing' : 'editortable_userselection'
              } font-design width textalign td_border_topbtm source_lang_col_padding table_cell_border
             ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
             ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`
          }
            dangerouslySetInnerHTML={{ __html: textdata.segment }}
          />
          <td
          className={
            `width textalign output_text td_border_topbtm table_cell_border target_lang_col_padding
            hov-border foc-border selected-border ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId
            === editDetails.textid) ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''} set-border-char-exceed
            ${ (sitsContext.editorHorizontalDragData.resizeFlag === true
            || sitsContext.editorResizeObj.resizeFlag === true) ? 'editortable_userselection_onresizing'
            : 'editortable_userselection'}`
          }
          id={
            'targetColumnEditorTable-' + editDetails.textid + '-' + textPartValue.partId + '-'
            + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
          }
          >
            <div className="grow-wrap" >
              <textarea  spellCheck='true'
                onPaste={() => trimExtraSpacesOnPaste()}
                id={
                  'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
                }
                className={
                  directionLang === "rtl" ? 'editable_field direction_rtl'
                  : 'editable_field direction_ltr'
                }
                onFocus={event=> onClickTableRowHandler(
                  event, textdata.translated_text, editDetails, textPartValue, textdata.segementID,
                  textdata, 'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
                  ) }
                onChange={
                  event => onChangeInputHandler(event,textdata.segment,
                  textdata.translated_text,textPartValue, editDetails.textid, editDetails.objectType,
                  editDetails.objNameShort, textdata.segementID, editDetails.category,
                  editDetails.sourceSystem, 'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber,
                  editDetails)}
                
                  onKeyUp={
                    (event) => setDynamicHeightHandler(
                      event, editDetails.textid , textPartValue.partId , textPartValue.partName,
                      textdata.segementID, serialNumber
                    )
                  }
                  defaultValue={
                    textdata.translated_text === null ? '' : textdata.translated_text
                  }/>
            </div>
          </td>
          {Number(textdata.baseScore) === 100
          && <td
          className={`match-column-width match-remove-border-vertical ${textdata.textChanged === true
          ? 'bg-color-on-change' : 'bg-color-high-accuracy-match'}
          td_border_topbtm content_editor table_cell_border table_content_padding
          ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
          ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`}>
            <div className={
              `match-font-design-all-accuracy ${textdata.textChanged === true
              ? 'match-font-color-on-change' : 'match-font-color-high-accuracy'}`
            }
            >
              {textdata.baseScore + "%"}
            </div>
          </td>
          }
          {(Number(textdata.baseScore) >= 30 && Number(textdata.baseScore) < 100)
          && <td 
            style={{maxWidth:'2.625rem'}} 
            className={`match-column-width match-remove-border-vertical ${textdata.textChanged === true
            ? 'bg-color-on-change' : 'bg-color-low-accuracy-match'}
            td_border_topbtm content_editor table_cell_border table_content_padding
            ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
            ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`}>
              <div className={
                `match-font-design-all-accuracy ${textdata.textChanged === true
                ? 'match-font-color-on-change' : 'match-font-color-low-accuracy'}`
              }
              >
                {textdata.baseScore + "%"}
              </div>
            </td>
          }
          {(Number(textdata.baseScore) < 30 || textdata.baseScore === null)
          && <td
              style={{maxWidth:'2.625rem'}}
              className={
                `match-column-width make-white-bg-color-hover td_border_topbtm content_editor
                table_cell_border table_content_padding 
                ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
                ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`
              }
             >
              -
            </td>
          }
          <td
            className={
              `td_border_topbtm table_cell_border table_content_padding hide-background-color-hover
              ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
              ? `set-border-right-on-row ${(textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'set-border-top-on-row' : ''}` : 'hide-border-on-confirm'}
              set-verticle-align-status`
              } 
            rowSpan={statusRowLength(editDetails.textpart)}
            style={{ display: (textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'null' : 'none' }}>
            <span className="status_plain_text">
              {textPartValue.status.charAt(0).toUpperCase() + textPartValue.status.slice(1)}
            </span>
            {editDetails.tag === "overdue"
              && <div className="tag_spacing">
                      <CommercialMessage variant="nlp" message="Overdue" />
                 </div>
            }
          </td>
         </tr>
        ))
      ))
       )}

{(reRender && editDetails.category !== undefined && editDetails.textpart.map((textPartValue, rowindex) => (
        textPartValue.segments.map((textdata, rowSegmentIndex) => (
         <tr
           key={
            (textPartValue.firstValidRow === true) ? ( rowSegmentIndex === 0 ? 0 : textPartValue.partId + '-'
            + textPartValue.partName + '-' + textdata.segementID)
            : (textPartValue.partId + '-' + textPartValue.partName + '-' + textdata.segementID)
           } 
           id="table-row"
           className={
             (
              !(isConfirmButtonIsClicked) && !(isTableRowChanged) && selectedTextDetails.textId
              === editDetails.textid  && selectedTextDetails.partId === textPartValue.partId
              && selectedTextDetails.partName === textPartValue.partName
              && textdata.segementID === selectedTextDetails.segmentId)
              ? `hide-background-color border-on-editable-area  table-row-design ${characterExceededError
              && compareText(editDetails.textid, textPartValue.partId, textPartValue.partName,
              textdata.segementID) ? 'border-on-char-exceed-error' : ''} `
              : `hide-background-color table-row-design ${characterExceededError
              && compareText(editDetails.textid, textPartValue.partId, textPartValue.partName,
              textdata.segementID)  ? 'border-on-char-exceed-error' : ''} `
            }
            onClick={
              event => onClickTableRowHandler(
                event, textdata.translated_text, editDetails, textPartValue, textdata.segementID,
                textdata, serialNumber
              )
            }
            style={{ display: (textdata.segment === null || textdata.segment === "") ? 'none' : 'null' }}
         >
          <td
            className={
              `width_serial_number remove_bottom_brorder textalign serial_no_editor serial_no_padding
              ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
              ? 'td-body-set-bordercolor' : 'td-body-set-bordercolor-noHighlight hide-border-onhover'}`
            } 
            rowSpan={
             (!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
             ? statusRowLength(editDetails.textpart) + 1 : statusRowLength(editDetails.textpart)
            }
            style={
              { display: (textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'null' : 'none' }
            }
          >
              {serialNumber+1}
          </td>
          <td
          className={
            `${ (sitsContext.editorHorizontalDragData.resizeFlag === true
               || sitsContext.editorResizeObj.resizeFlag === true)
               ? 'editortable_userselection_onresizing' : 'editortable_userselection'
              } font-design width textalign td_border_topbtm source_lang_col_padding table_cell_border
             ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
             ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`
          }
            dangerouslySetInnerHTML={{ __html: textdata.segment }}
          />
          <td
          className={
            `width textalign output_text td_border_topbtm table_cell_border target_lang_col_padding
            hov-border foc-border selected-border ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId
            === editDetails.textid) ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''} set-border-char-exceed
            ${ (sitsContext.editorHorizontalDragData.resizeFlag === true
            || sitsContext.editorResizeObj.resizeFlag === true) ? 'editortable_userselection_onresizing'
            : 'editortable_userselection'}`
          }
          id={
            'targetColumnEditorTable-' + editDetails.textid + '-' + textPartValue.partId + '-'
            + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
          }
          >
            <div className="grow-wrap" >
              <textarea  spellCheck='true'
                onPaste={() => trimExtraSpacesOnPaste()}
                id={
                  'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
                }
                className={
                  directionLang === "rtl" ? 'editable_field direction_rtl'
                  : 'editable_field direction_ltr'
                }
                onFocus={event=> onClickTableRowHandler(
                  event, textdata.translated_text, editDetails, textPartValue, textdata.segementID,
                  textdata, 'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber
                  ) }
                onChange={
                  event => onChangeInputHandler(event,textdata.segment,
                  textdata.translated_text,textPartValue, editDetails.textid, editDetails.objectType,
                  editDetails.objNameShort, textdata.segementID, editDetails.category,
                  editDetails.sourceSystem, 'editableField-' + editDetails.textid + '-' + textPartValue.partId + '-'
                  + textPartValue.partName + '-' + textdata.segementID + '-' + serialNumber)}
                
                  onKeyUp={
                    (event) => setDynamicHeightHandler(
                      event, editDetails.textid , textPartValue.partId , textPartValue.partName,
                      textdata.segementID, serialNumber
                    )
                  }
                  defaultValue={
                    textdata.translated_text === null ? '' : textdata.translated_text
                  }/>
            </div>
          </td>
          {Number(textdata.baseScore) === 100
          && <td
          className={`match-column-width match-remove-border-vertical ${textdata.textChanged === true
          ? 'bg-color-on-change' : 'bg-color-high-accuracy-match'}
          td_border_topbtm content_editor table_cell_border table_content_padding
          ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
          ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`}>
            <div className={
              `match-font-design-all-accuracy ${textdata.textChanged === true
              ? 'match-font-color-on-change' : 'match-font-color-high-accuracy'}`
            }
            >
              {textdata.baseScore + "%"}
            </div>
          </td>
          }
          {(Number(textdata.baseScore) >= 30 && Number(textdata.baseScore) < 100)
          && <td 
            style={{maxWidth:'2.625rem'}} 
            className={`match-column-width match-remove-border-vertical ${textdata.textChanged === true
            ? 'bg-color-on-change' : 'bg-color-low-accuracy-match'}
            td_border_topbtm content_editor table_cell_border table_content_padding
            ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
            ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`}>
              <div className={
                `match-font-design-all-accuracy ${textdata.textChanged === true
                ? 'match-font-color-on-change' : 'match-font-color-low-accuracy'}`
              }
              >
                {textdata.baseScore + "%"}
              </div>
            </td>
          }
          {(Number(textdata.baseScore) < 30 || textdata.baseScore === null)
          && <td
              style={{maxWidth:'2.625rem'}}
              className={
                `match-column-width make-white-bg-color-hover td_border_topbtm content_editor
                table_cell_border table_content_padding 
                ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
                ? (textPartValue.firstValidRow === true && rowSegmentIndex === 0 && 'set-border-top-on-row') : ''}`
              }
             >
              -
            </td>
          }
          <td
            className={
              `td_border_topbtm table_cell_border table_content_padding hide-background-color-hover
              ${(!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
              ? `set-border-right-on-row ${(textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'set-border-top-on-row' : ''}` : 'hide-border-on-confirm'}
              set-verticle-align-status`
              } 
            rowSpan={statusRowLength(editDetails.textpart)}
            style={{ display: (textPartValue.firstValidRow === true && rowSegmentIndex === 0) ? 'null' : 'none' }}>
            <span className="status_plain_text">
              {textPartValue.status.charAt(0).toUpperCase() + textPartValue.status.slice(1)}
            </span>
            {editDetails.tag === "overdue"
              && <div className="tag_spacing">
                      <CommercialMessage variant="nlp" message="Overdue" />
                 </div>
            }
          </td>
         </tr>
        ))
      ))
       )}     
      <tr
         style=
         {
          {
            display: ( !isConfirmButtonIsClicked && selectedTextDetails.textId === editDetails.textid)
            ? 'table-row':'none', height :( !isConfirmButtonIsClicked && selectedTextDetails.textId
            === editDetails.textid) ? "4.5rem" : "0rem"
          }
         } 
         className={
          !isConfirmButtonIsClicked
          ? 'meta_data_row hide-background-color-dupl': 'hide-background-color-dupl meta_data_no_display'
         }
      >
        <td
          className={
            (!isConfirmButtonIsClicked  && selectedTextDetails.textId === editDetails.textid)
            ? 'meta_data_row_no_display_td' : ''
          }
        >          
        </td>
        <td
          colSpan={
            ((sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
              && sitsContext.paragraphMismatchErrorEditor.textId === selectedSegment.textId)
            || (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
              && sitsContext.textPartEmptyErrorEditor.textid === selectedSegment.textId
            ))
            ? 2 :  3
          }
          className={
            `meta-data-row-padding ${(!isConfirmButtonIsClicked && selectedTextDetails.textId
            === editDetails.textid) ? 'set-border-bottom-on-row' : ''}`
          }
        > 
          <Pill
            tabIndex="-1"
            key='1'
            name='Object Type'
            selected={false}
            iconPosition="leading"
            label={
            <div className='pillDetails_align'>
              <p className='pillHeader_align'>
                Object Type
              </p>
              <p className='pillDescription_align'>
                {metaDataValue.objectType === null ? '-' : metaDataValue.objectType}
              </p>
            </div>}
            small={true}
            className='Noto_font_family pill_design pillText_design'
          />
          {!(metaDataForObjects.categoryDisabledObjects).includes(metaDataValue.objectType) ? (
          <Pill
            tabIndex="-1"
            key='2'
            name='Category'
            selected={false}
            iconPosition="leading"
            label={
            <div className='pillDetails_align'>
              <p className='pillHeader_align'>
                Category
              </p>
              <p className='pillDescription_align'>
                {metaDataValue.category === null ? '-' : metaDataValue.category}
              </p>
            </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          />
        ) : ''}
           {(metaDataForObjects.nameEnabledObjects).includes(metaDataValue.objectType) ? (
          <Pill
            tabIndex="-1"
            key='9'
            name='Name'
            selected={false}
            iconPosition="leading"
            label={
            <div className='pillDetails_align_name'>
              <p className='pillHeader_align'>
              Name
              </p>
              <p className='pillDescription_name'>
                {metaDataValue.name === null ? '-' : metaDataValue.name}
              </p>
            </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          /> 
           ) : ''}
          { characterCountMetadata && (metaDataForObjects.descriptionEnabledObjects).includes(metaDataValue.objectType)  ? (
          <Pill
            tabIndex="-1"
            key='7'
            name='Description'
            selected={false}
            focuslockprops={{ disabled: false }}
            iconPosition="leading"
            label={
            <div className= {expandButton ? 'pillDetails_align_expander':'pillDetails_align'}>
              <p className='pillHeader_align'>
              Description
              </p>
              {(!expandButton && metaDataValue.description !== undefined) &&
              <p className='pillDescription_align'>
                 {metaDataValue.description === null ? '-':
                 (metaDataValue.description.length < characterCountMetadata ? metaDataValue.description :
                   <span>{metaDataValue.description.substring(0, characterCountMetadata)}...</span>
                )}
                {(metaDataValue.description !== undefined && metaDataValue.description !== null && metaDataValue.description.length > characterCountMetadata) && <span className='show_more_less_alignment' onClick={()=>{showMoreDescription('show more', metaDataValue.description, characterCountMetadata)}}>show more</span>}
              </p>}
              {(expandButton && metaDataValue.description !==undefined) &&
              <span className='pillDescription_align_expander'>
                {extendedDescription.map((paragraph, index) => (<p key={index}>{paragraph}
                  {extendedDescription.length - 1 === index &&
                  <span>...<span className='show_more_less_alignment' onClick={()=>{showMoreDescription('show less')}}>show less</span></span>}</p>))}
              </span>}
            </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          /> 
           ) : ''}

          {characterCountMetadata && (metaDataForObjects.usedInEnabledObjects.includes(metaDataValue.objectType)) && (
          <Pill
            tabIndex="-1"
            key='17'
            name='Used In'
            selected={false}
            focuslockprops={{ disabled: false }}
            iconPosition="leading"
            label={
            <div className= {usedInExpandButton ? 'pillDetails_align_expander':'pillDetails_align'}>
              <p className='pillHeader_align'>
              Used In
              </p>
              {(!usedInExpandButton && metaDataValue.usedin !== undefined) &&
              <p className='pillDescription_align'>
                 {metaDataValue.usedin === null ? '-':
                 (metaDataValue.usedin.length < characterCountMetadata ? metaDataValue.usedin.split(',').join(', ') :
                   <span>{metaDataValue.usedin.split(',').join(', ').substring(0, characterCountMetadata)}...</span>
                )}
                {(metaDataValue.usedin !== undefined
                  && metaDataValue.usedin !== null
                  && metaDataValue.usedin.length > characterCountMetadata)
                  && <span
                  className='show_more_less_alignment'
                  onClick={()=>{showMoreUsedin('show more', metaDataValue.usedin, characterCountMetadata)}}>
                    show more
                    </span>}
              </p>}
              {(usedInExpandButton && metaDataValue.usedin !==undefined) &&
              <span className='pillDescription_align_expander'>
                {extendedUsedIn.map((paragraph, index) => (<p key={index}>{paragraph.split(',').join(', ')}
                  {extendedUsedIn.length - 1 === index &&
                  <span>...<span className='show_more_less_alignment' onClick={()=>{showMoreUsedin('show less')}}>show less</span></span>}</p>))}
              </span>}
            </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          /> 
           )}  
          {(metaDataForObjects.mainProductTypeEnabledObjects.includes(metaDataValue.objectType)) ? (
          <Pill
            tabIndex="-1"
            key='8'
            name='Main product type'
            selected={false}
            iconPosition="leading"
            label={
            <div className='pillDetails_align'>
              <p className='pillHeader_align'>
              Main product type
              </p>
              <p className='pillDescription_align'>
              {metaDataValue.mainProductType === null ? '-' : metaDataValue.mainProductType}
              </p>
            </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          /> 
           ) : ''}

          <Pill
            tabIndex="-1"
            key='3'
            name='Text Type'
            selected={false}
            iconPosition="leading"
            label={
              <div className='pillDetails_align'>
                <p className='pillHeader_align'>
                  Type
                </p>
                <p className='pillDescription_align'>
                  {metaDataValue.type === null ? '-' : metaDataValue.type}
                </p>
              </div>
            }
            small={true}
            className={
              `Noto_font_family pill_design pillText_design
              ${(((sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
                && sitsContext.textPartEmptyErrorEditor.textId === selectedSegment.textId)
                && sitsContext.textPartEmptyErrorEditor.textPartNameEmpty.includes(metaDataValue.type))
                || (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true
                  && sitsContext.duplicateTranslation.textId === selectedSegment.textId
                  && metaDataValue.type.toLowerCase() === "name"))
                ? 'border-metadata-char-exceed-error' : ''
              }`
            }
          />
          <Pill
            tabIndex="-1"
            key='4'
            name='Updated by'
            selected={false}
            iconPosition="leading"
            label={
              <div className='pillDetails_align'>
                <p className='pillHeader_align'>
                  Updated by
                </p>
                <p className='pillDescription_align'>
                  {(metaDataValue.updatedBy === null || metaDataValue.updatedBy === 'undefined'
                  || metaDataValue.updatedBy === ' ') ? '-' : metaDataValue.updatedBy}
                </p>
              </div>
            }
            small={true}
            className='Noto_font_family pill_design pillText_design'
          />
          {!characterExceededData.selectedPartHasError
          && <Pill
               tabIndex="-1"
               key='5'
               name='Character count'
               selected={false}
               iconPosition="leading"
               className = {`pill_design pillText_design`}
               label={
                 <div className='pillDetails_align'>
                  <p className='pillHeader_align'>
                    Character count
                  </p>
                  <p className='pillDescription_align'>
                    {metaDataValue.charCount === null ? '-' : metaDataValue.charCount}
                  </p>
                 </div>
               }
               small={true}
             />
          }
          {characterExceededData.selectedPartHasError
          && <Pill
               tabIndex="-1"
               key='5'
               name='Character count'
               selected={false}
               iconPosition="leading"
               className = {
                `pill_design pill_padding_reduce pillText_design ${characterExceededData.selectedPartHasError
                ? 'border-metadata-char-exceed-error' : ''}`
               } 
               label={
               <div className='metadata-flex-container'>
                <div className='metadata-flex-items metadata-center-vertical'>
                  <SSRIcon paths={incorrect} className='icon_incorrect'/>
                </div>
                <div className='pillDetails_align metadata-flex-items'>
                  <p className='pillHeader_align'>
                    Character count
                  </p>
                  <p className='pillDescription_align'>
                    {metaDataValue.charCount === null ? '-' : metaDataValue.charCount}
                  </p>
                </div>
               </div>}
               small={true}
              />
          }
        </td>
        <td
        className={
          `table_cell_border remove-border-left ${(!isConfirmButtonIsClicked
          && selectedTextDetails.textId === editDetails.textid)
          ? 'set-border-bottom-on-row set-border-right-on-row' : ''}`
        }
        colSpan={
          ((sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
            && sitsContext.paragraphMismatchErrorEditor.textId === selectedSegment.textId)
          || (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
          && sitsContext.textPartEmptyErrorEditor.textId === selectedSegment.textId))
          ? 2 :  1
        }
        >
          <div className={
            ((sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
              && sitsContext.paragraphMismatchErrorEditor.textId === selectedSegment.textId)
              || (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
                && sitsContext.textPartEmptyErrorEditor.textId === selectedSegment.textId
              )) ?
              'confirm_button-container confirm_buttom-spaceContainer' : 'confirm_button-container'}>
          {
            ((sitsContext.paragraphMismatchErrorEditor.paragraphMismatchErrorFlag === true
              && sitsContext.paragraphMismatchErrorEditor.textId === selectedSegment.textId)
              || (sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
                && sitsContext.textPartEmptyErrorEditor.textId === selectedSegment.textId)
              || (sitsContext.duplicateTranslation.duplicateTranslationErrorFlag === true
                && sitsContext.duplicateTranslation.textId === selectedSegment.textId)
              || (sitsContext.segmentsMissingValidation.segmentsMissingValidationFlag === true
                && sitsContext.segmentsMissingValidation.textId === selectedSegment.textId
              ))
              && (
                <Tooltip
                className='validation_error-tooltip'
                describedById="validationErrorTooltip"
                tooltipButtonLabel="Validation error tooltip"
                position="top"
                tooltipText={validationTooltipMessage}
                toggle={false}
                >  
                <Button
                  iconOnly
                  ssrIcon={incorrect}
                  type='tertiary'
                  className='confirm_button-errorValidation'
                  id="validationErrorIcon"
                />
               </Tooltip>
            )
          }
          <Button
            title={
              characterExceededError ? `Text exceeds allowed limit of
              ${characterExceededData.maximumCharacterCount} characters. Please shorten.` : ''
            }
            text="Confirm"
            type='secondary'
            small={true}
            disabled={characterExceededError ? true: false}
            onClick={() => errorCheckOnConfirmHandler(true, undefined)}
            loading= {
              (editDetails.confirmationInProgress === undefined || editDetails.confirmationInProgress
              === false) ? false: true
            }
            className='btn_inner_design'
            id='confirmButtonEditor'
          />
          </div>
        </td>
      </tr>
      <tr className="spacing" /> 
    </TableBody>))}
  </Table>
</div>
<div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
  <EditorBottomLayout
    updateEditorBottomHeight = {updateEditorBottomHeightHandler}
    segmentSelected = {selectedSegment}
    editorResizeValue = {editorBottomResizeValue}
    updateResizeValue = {updateResizeValue}
    showHistoryTabFlag = {showHistoryTab}
  /></div>
</div>
  );
})


export default Editorpage;
